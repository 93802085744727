@import '../../styles/colors';
@import '../../styles/breakpoints';

.header-wrapper {
  background-color: map-get($brand, beige);
  padding: 32px 140px;
  position: relative;

  @include xxl-breakpoint {
    padding: 60px 128px;
  }

  @include xl-breakpoint {
    padding: 60px 96px;
  }

  @include lg-breakpoint {
    padding: 60px 80px;
  }

  @include md-breakpoint {
    padding: 24px 40px;
  }

  @include sm-breakpoint {
    padding: 24px 24px;
  }

  @include xs-breakpoint {
    padding: 24px 24px;
  }
}

footer {
  position: relative;
  background-color: map-get($brand, green);
  padding: 60px 140px 20px;

  @include xxl-breakpoint {
    padding: 60px 128px;
  }

  @include xl-breakpoint {
    padding: 60px 96px;
  }

  @include lg-breakpoint {
    padding: 60px 80px;
  }

  @include md-breakpoint {
    padding: 24px 40px;
  }

  @include sm-breakpoint {
    padding: 24px 24px;
  }

  @include xs-breakpoint {
    padding: 24px 24px;
  }
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &:has(.navigation.open) {
    @include mobile-breakpoint {
      overflow: hidden;
    }
  }
}

.main-wrapper {
  background-color: map-get($brand, beige);
  padding: 40px 140px 140px 140px;
  position: relative;
  flex: 1;

  @include xxl-breakpoint {
    padding: 32px 128px 128px 128px;
  }

  @include xl-breakpoint {
    padding: 24px 96px 96px 96px;
  }

  @include lg-breakpoint {
    padding: 20px 80px 109px 80px;
  }

  @include md-breakpoint {
    padding: 20px 40px 109px 40px;
  }

  @include sm-breakpoint {
    padding: 20px 40px 80px 40px;
  }

  @include xs-breakpoint {
    padding: 20px 24px 80px 24px;
  }
}

.lock-scroll {
  overflow: hidden;
}
