@import './colors';

@mixin heading2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

@mixin backgroundOpacity($color, $opacity: 1) {
  background: rgba($color, $opacity);
}

@mixin fontColorOpacity($color, $opacity) {
  color: rgba($color, $opacity);
}

@mixin heading3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: $black;
}

@mixin arrow-left {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  z-index: 100;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid map-get($brand, green);
}

@mixin arrow-top {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  z-index: 100;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid map-get($brand, green);
}

@mixin box-shadow {
  border: 1px solid map-get($beige, 500);
  box-shadow: 0px 2px 4px -1px rgba(20, 50, 80, 0.12), 0px 1px 10px 0px rgba(3, 12, 37, 0.1),
    0px 2px 5px 0px rgba(3, 12, 37, 0.12);
}

@mixin box-shadow-inputs {
  box-shadow: 0px 4px 4px 0px rgba(201, 199, 195, 0.2);
}

@mixin grid-equal-cols($gap, $colsNumber, $colMinWidth) {
  $gapCount: calc($colsNumber - 1);
  $totalGapWidth: calc($gapCount * $gap);
  $colMaxWidth: calc((100% - $totalGapWidth) / $colsNumber);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max($colMinWidth, $colMaxWidth), 1fr));
  grid-gap: $gap;
}
