@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';

.solar-system-header {
  .solar-system-heading {
    margin-top: 18px;
    margin-bottom: 40px;

    @include tablet-breakpoint {
      margin-bottom: 18px;
    }
  }
}

.solar-system-wrapper {
  display: grid;
  grid-template-columns: 7fr 5fr;
  width: 100%;
  height: 80%;
  column-gap: 40px;
  padding: 2rem 0;

  @include xl-breakpoint {
    grid-template-columns: 5fr 4fr;
  }

  @include lg-breakpoint {
    display: flex;
    flex-direction: column;
  }

  @include tablet-breakpoint {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  .solar-system {
    & > h3 {
      margin-bottom: 32px;

      @include tablet-breakpoint {
        margin-bottom: 24px;
      }

      @include mobile-breakpoint {
        margin-bottom: 24px;
      }
    }
    & > p {
      margin-bottom: 0.5rem;
    }

    .modules-wrapper {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: #{$xsBreakpoint}px) and (max-width: #{$lgBreakpoint - 1}px) {
        flex-flow: column-reverse;
      }

      .solar-system-modules {
        > div {
          margin-top: 40px;
          margin-bottom: 24px;

          @include tablet-breakpoint {
            margin-top: 24px;
          }

          @include mobile-breakpoint {
            margin-top: 24px;
          }
        }

        .solar-system-modules-text {
          color: $black;
        }
      }
    }
  }
}

.financing-offer {
  grid-column: 2;
  grid-row: 1/3;

  & > button {
    width: 100%;
  }
}

.solar-system-sidebar {
  isolation: isolate;

  .tooltip-header-wrapper{
    .tooltip-text-wrapper{
      transform: translateX(30%);
    }
    .info-icon:hover + .tooltip-text-wrapper::before {
      left: 70%;
    }
  }
}
