@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';

.search-wrapper {
  width: 100%;
  height: 80px;
  position: relative;
  background-color: $white;
  border: 2px solid map-get($beige, 1000);
  border-radius: 4px;
  transition: border-color 0.4s ease;

  &:hover {
    border-color: map-get($green, 600);
  }

  @include tablet-breakpoint {
    height: 64px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }

  .error {
    path {
      fill: map-get($status, error);
    }

    input::placeholder {
      color: map-get($status, error);
    }
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 20px;
    line-height: 28px;
    font-family: 'Poppins-Medium';
    margin: auto 0;
    color: map-get($brand, green);

    @include tablet-breakpoint {
      font-size: 16px;
      line-height: 24px;
    }

    @include mobile-breakpoint {
      font-size: 15px;
    }
  }

  svg {
    margin: auto;
    width: 32px;
    height: 32px;
    path {
      fill: map-get($brand, green);
    }
  }
  .search-input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
    .close-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .options {
    display: none;
  }
}

.search-wrapper.display {
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid map-get($beige, 1000);
  border-left: 2px solid map-get($brand, orange);
  border-right: 2px solid map-get($brand, orange);
  border-top: 2px solid map-get($brand, orange);

  .inputContainer {
    position: relative;
  }
  .options {
    position: absolute;
    display: inline-block;
    top: 101%;
    left: -2px;
    right: -2px;
    z-index: 2;
    background-color: $white;
    padding: 10px 10px;
    border-left: 2px solid map-get($brand, orange);
    border-right: 2px solid map-get($brand, orange);
    border-bottom: 2px solid map-get($brand, orange);
    border-end-start-radius: 4px;
    border-end-end-radius: 4px;
    max-height: 350px;
    overflow-y: scroll;

    & > span {
      display: block;
      padding-left: 10px;
      color: map-get($brand, green);
      font-family: 'Poppins-Medium';
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        background-color: map-get($brand, beige);
      }
      &.no-option:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
