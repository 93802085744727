@import '../../styles/colors';
@import '../../styles/breakpoints';

.kyc-identification-wrapper {
  width: 57%;

  @include tablet-breakpoint {
    width: 100%;
  }

  @include mobile-breakpoint {
    width: 100%;
  }

  > h2 {
    margin-bottom: 48px;
  }

  > p {
    margin-bottom: 32px;
  }

  .kyc-radio-actions-wrapper {
    display: flex;
    align-items: center;
    gap: 32px;

    @include tablet-breakpoint {
      align-items: flex-start;
      flex-direction: column;
      gap: 28px;
      margin-bottom: 20px;
    }

    @include mobile-breakpoint {
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }
  }

  img {
    width: 192px;
    height: 192px;
    padding: 24px;
    background-color: $white;
    margin-bottom: 24px;
  }

  > span {
    display: block;
  }

  .ident-id-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;

    .ident-id {
      display: flex;
      gap: 16px;
      align-items: center;
      border: 2px solid map-get($brand, green);
      padding: 8px 16px;

      svg {
        cursor: pointer;

        path {
          fill: map-get($brand, green);
        }
      }
    }
  }

  .kyc-link-qr {
    padding: 30px;
    background-color: $white;
  }
}
