.partner-levels-wrapper {
  .table-wrapper {
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      padding: 0;
    }
  }

  .button {
    margin-top: 40px;
  }
}
