@import '../../styles/colors';

.language-selector-wrapper {
  position: relative;

  .selected-language {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;

    .arrow {
      border: solid map-get($brand, green);
      border-width: 0 2px 2px 0;
      vertical-align: middle;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .language-dropdown-wrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    height: fit-content;
    background-color: map-get($orange, 1000);
    z-index: -1;
    height: 0;
    transition: height 0.3s ease-in-out;

    li {
      cursor: pointer;
      padding: 10px 8px 10px 10px;
      display: none;

      &:hover {
        background-color: map-get($brand, beige);
      }
    }
  }
}

.language-selector-wrapper.open {
  .language-dropdown-wrapper {
    height: 100%;
    z-index: 1;
    box-shadow: 0px 2px 4px -1px rgba(20, 50, 80, 0.12), 0px 1px 10px 0px rgba(3, 12, 37, 0.1),
      0px 2px 5px 0px rgba(3, 12, 37, 0.12);

    li {
      display: block;
    }
  }

  .arrow {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}
