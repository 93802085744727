@import '../../styles/mixin';
@import '../../styles/colors';

.breadcrumbs {
  margin-bottom: 18px;
  a {
    padding: 0 20px;
    border-right: 1px solid map-get($brand, green);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      pointer-events: none;

      .sak-text {
        color: map-get($green, 600);
      }
    }
  }

  > .button.medium {
    padding-left: 0px;
    &:hover {
      border-color: transparent;
    }
    &:focus {
      border-color: transparent;
    }
  }
}
