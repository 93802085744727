@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixin';
@import '../../../styles/breakpoints';

.input-container {
  height: 80px;
  position: relative;
  width: 100%;
  @include box-shadow-inputs;

  @include tablet-breakpoint {
    height: 64px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }

  .input {
    background-color: $white;
    font-family: 'Poppins-Medium';
    font-size: 18px;
    border: 2px solid map-get($beige, 1000);
    border-radius: $radius-small;
    color: map-get($brand, green);
    height: 100%;
    outline: 0;
    width: 100%;
    padding: 14px 20px 0 20px;
    transition: border-color 0.4s ease;

    @include mobile-breakpoint {
      font-size: 16px;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='date'] {
      -webkit-appearance: none;
    }

    &:hover {
      border-color: map-get($green, 600);
    }

    &:focus {
      border-color: map-get($brand, orange);
    }
  }

  svg {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    path {
      fill: map-get($brand, green);
    }
  }

  .sak-text {
    &.placeholder {
      padding-top: 16px;
      left: 20px;
      line-height: 14px;
      pointer-events: none;
      position: absolute;
      transform-origin: 0 50%;
      transition: transform 200ms, color 200ms;
      top: 50%;
      transform: translateY(-70%);
    }

    &.unit {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-40%);
    }
  }

  &.error {
    path {
      fill: map-get($status, error);
    }

    .placeholder,
    .input:not(:placeholder-shown) ~ .placeholder.sak-text,
    .error-text {
      color: map-get($status, error);
    }

    .error-text {
      margin-left: 20px;
    }

    > p {
      color: map-get($status, error);
    }
  }

  &.disabled {
    pointer-events: none;

    .input {
      border-color: map-get($beige, 1000);
      color: map-get($green, 800);
      background-color: map-get($brand, beige);
    }

    .input:not(:placeholder-shown) ~ .placeholder.sak-text,
    .error-text {
      color: map-get($grey, base);
    }

    path {
      fill: map-get($grey, base);
    }
  }
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder.sak-text {
  transform: translateY(-41px) translateX(0px);
  font-size: 14px;
  color: map-get($brand, green);
}

.input:focus ~ .placeholder {
  color: map-get($green, 600);
}

.input:hover:not(:focus) {
  & + svg path {
    fill: map-get($green, 600);
  }
}
