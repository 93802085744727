@import '../../styles/colors';
@import '../../styles/breakpoints';

.sep-legend-wrapper {
  .legend::before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    margin-left: 10px;

    @include mobile-breakpoint {
      margin-left: 0;
    }
  }

  .legend:first-child::before {
    margin-left: 0;
  }

  @include mobile-breakpoint {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.yellow::before {
  background-color: map-get($sepLegend, yellow);
}

.blue::before {
  background-color: map-get($sepLegend, blue);
}

.dark-blue::before {
  background-color: map-get($sepLegend, dark-blue);
}

.orange::before {
  background-color: map-get($sepLegend, orange);
}

.red::before {
  background-color: map-get($sepLegend, red);
}
