@import '../../../styles/colors';
@import '../../../styles/mixin';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.switch-wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 108px;

  @include mobile-breakpoint {
    flex-direction: column;
  }

  .on {
    width: 80px;
    text-align: right;
  }

  .switch-content {
    display: flex;
    padding: 18px 32px 18px 32px;

    flex-grow: 1;
    flex-direction: column;

    @include mobile-breakpoint {
      text-align: center;
    }
  }

  .controls {
    padding-right: 32px;
    padding-left: 32px;

    @include mobile-breakpoint {
      padding-bottom: 15px;
      margin-top: 19px;
    }

    .switch {
      position: relative;
      display: block;
      width: 48px;
      height: 24px;

      input {
        display: none;
      }

      .slider {
        position: absolute;
        cursor: pointer;

        width: 48px;
        height: 24px;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: $white;
        border: 2px solid map-get($status, error);

        -webkit-transition: 0.4s;
        transition: all 0.4s;
        border-radius: 34px;
      }

      .slider:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 10px;
        top: 10px;

        transform: translate(-50%, -50%);
        transition: 0.4s;

        background-color: map-get($status, error);
        border-radius: 50%;
        border: 1px solid map-get($status, error);
      }
    }

    .checkbox:checked {
      & + .slider {
        background-color: map-get($status, success);
        border-color: map-get($status, success);
      }

      & + .slider:before {
        border: none;
        background-color: $white;
        transform: translate(100%, -50%);
      }
    }
  }

  &.disabled {
    .controls {
      .switch {
        pointer-events: none;
      }

      .slider {
        border: 2px solid map-get($beige, 750);
      }

      .slider:before {
        background-color: map-get($beige, 750);
        border: 1px solid map-get($beige, 750);
      }

      .checkbox:checked {
        & + .slider {
          background-color: map-get($beige, 750);
          border-color: map-get($beige, 750);
        }
      }
    }
  }

  &.inactive {
    background-color: map-get($brand, beige);

    .controls {
      .switch {
        pointer-events: none;
      }
    }
  }
}
