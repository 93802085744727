@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.file-item {
  height: 80px;
  padding: 12px 32px 12px 16px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  outline: solid 2px map-get($orange, 200);

  @include tablet-breakpoint {
    height: 64px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }

  .remove-icon {
    margin-left: 21px;
    cursor: pointer;
  }

  & > * {
    margin: auto 0;
  }

  .file-label {
    padding-left: 1rem;
    width: 100%;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;

    @include mobile-breakpoint {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;
    }
  }

  &.right {
    flex-direction: row-reverse;

    .file-label {
      padding-right: 1rem;
      text-align: right;
    }
  }

  &.disabled {
    outline-color: map-get($beige, 1000);
    background-color: map-get($brand, beige);

    & > .sak-text {
      color: map-get($green, 800);
    }

    .file-icon {
      path {
        fill: map-get($grey, base);
      }
    }
  }
}
