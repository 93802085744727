@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.text-area-input-container {
  height: 212px;
  position: relative;
  width: 100%;

  @include tablet-breakpoint {
    height: 128px;
  }

  @include mobile-breakpoint {
    height: 128px;
  }

  .text-area-input {
    background-color: $white;
    font-family: 'Poppins-Medium';
    font-size: 18px;
    border: 2px solid map-get($beige, 1000);
    border-radius: $radius-small;
    color: map-get($brand, green);
    outline: 0;
    padding: 30px 20px 14px 20px;
    transition: border-color 0.4s ease;
    width: 100%;
    height: 100%;
    resize: none;

    &:hover {
      border-color: map-get($green, 600);
    }

    &:focus {
      border-color: map-get($brand, orange);
    }
  }

  .placeholder {
    color: map-get($green, 1000);
    font-family: 'Poppins-Medium';
    left: 22px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 25%;
    transform: translateY(-70%);

    @include tablet-breakpoint {
      top: 35%;
    }

    @include mobile-breakpoint {
      top: 35%;
    }
  }

  .text-area-input:focus ~ .placeholder,
  .text-area-input:not(:placeholder-shown) ~ .placeholder.sak-text {
    transform: translateY(-41px) translateX(0px);
    font-size: 14px;
    color: map-get($brand, green);
  }

  .text-area-input:focus ~ .placeholder {
    color: map-get($green, 600);
  }

  &.error {
    path {
      fill: map-get($status, error);
    }

    .placeholder,
    .text-area-input:not(:placeholder-shown) ~ .placeholder.sak-text,
    .error-text {
      color: map-get($status, error);
    }

    .error-text {
      margin-left: 20px;
    }
  }

  &.disabled {
    pointer-events: none;

    .text-area-input {
      border-color: map-get($beige, 1000);
      color: map-get($green, 800);
      background-color: map-get($brand, beige);
    }

    .text-area-input:focus ~ .placeholder,
    .text-area-input:not(:placeholder-shown) ~ .placeholder.sak-text,
    .error-text {
      color: map-get($grey, base);
    }
  }
}
