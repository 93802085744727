@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.search-form {
  border: 2px solid map-get($beige, 1000);
  border-radius: $radius-small;
  background-color: $white;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px;

  input {
    width: 100%;
    display: block;
    border: none;
    box-shadow: none;
    outline: none;
    color: map-get($brand, green);
    font-family: 'Poppins-Medium';
    font-size: 20px;
    line-height: 28px;

    @include tablet-breakpoint {
      font-size: 16px;
    }

    @include mobile-breakpoint {
      font-size: 16px;
    }

    &::placeholder {
      font-family: 'Poppins-Medium';
      font-size: 20px;
      line-height: 28px;
      color: map-get($green, 1000);

      @include tablet-breakpoint {
        font-size: 16px;
      }

      @include mobile-breakpoint {
        font-size: 16px;
      }
    }
  }

  svg {
    border: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;

    @include tablet-breakpoint {
      width: 36px;
      height: 36px;
    }

    @include mobile-breakpoint {
      width: 30px;
      height: 30px;
    }

    path {
      fill: map-get($green, 1000);
    }
  }
}
