$xsBreakpoint: 600;
$smBreakpoint: 768;
$mdBreakpoint: 1024;
$lgBreakpoint: 1280;
$xlBreakpoint: 1440;
$xxlBreakpoint: 1600;
$xxxlBreakpoint: 1920;

/*** MAIN DEVICE BREAKPOINTS **/

@mixin mobile-breakpoint {
  @media only screen and (max-width: #{$smBreakpoint - 1}px) {
    @content;
  }
}

@mixin tablet-breakpoint {
  @media only screen and (min-width: #{$smBreakpoint}px) and (max-width: #{$mdBreakpoint - 1}px) {
    @content;
  }
}

@mixin desktop-breakpoint {
  @media only screen and (min-width: #{$mdBreakpoint}px) {
    @content;
  }
}

/** DESIGN SPECIFIED BREAKPOINTS **/

@mixin xs-breakpoint {
  @media only screen and (max-width: #{$xsBreakpoint - 1}px) {
    @content;
  }
}

@mixin sm-breakpoint {
  @media only screen and (min-width: #{$xsBreakpoint}px) and (max-width: #{$smBreakpoint - 1}px) {
    @content;
  }
}

@mixin md-breakpoint {
  @media only screen and (min-width: #{$smBreakpoint}px) and (max-width: #{$mdBreakpoint - 1}px) {
    @content;
  }
}

@mixin lg-breakpoint {
  @media only screen and (min-width: #{$mdBreakpoint}px) and (max-width: #{$lgBreakpoint - 1}px) {
    @content;
  }
}

@mixin xl-breakpoint {
  @media only screen and (min-width: #{$lgBreakpoint}px) and (max-width: #{$xlBreakpoint - 1}px) {
    @content;
  }
}

@mixin xxl-breakpoint {
  @media only screen and (min-width: #{$xlBreakpoint}px) and (max-width: #{$xxlBreakpoint - 1}px) {
    @content;
  }
}

@mixin xxxl-breakpoint {
  @media only screen and (min-width: #{$xxlBreakpoint}px) {
    @content;
  }
}
