.partner-details-wrapper {
  > h2 {
    margin-bottom: 40px;
  }
  .tabs {
    > ul {
      margin-bottom: 40px;
    }
  }
}
