@import '../../styles/colors';

.nav-link {
  padding: 10px 0;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.4s ease-out;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

a.right {
  flex-direction: row-reverse;
}

.nav-link.primary {
  & path {
    fill: map-get($green, 600);
  }

  &:hover {
    border-color: map-get($green, 600);

    .sak-text {
      color: map-get($green, 600);
    }

    path {
      fill: map-get($green, 600);
    }
  }

  &:focus {
    border-color: map-get($green, 250);

    .sak-text {
      color: map-get($green, 250);
    }

    path {
      fill: map-get($green, 250);
    }
  }

  &.active {
    border-color: map-get($brand, orange);

    .sak-text {
      color: map-get($brand, orange);
    }

    path {
      fill: map-get($brand, orange);
    }
  }

  &.disabled {
    pointer-events: none;

    .sak-text {
      color: map-get($grey, base);
    }

    path {
      fill: map-get($grey, base);
    }
  }
}

.nav-link.secondary {
  .sak-text {
    color: $white;
  }

  &:hover {
    border-color: map-get($brand, orange);

    .sak-text {
      color: map-get($brand, orange);
    }

    path {
      fill: map-get($brand, orange);
    }
  }

  &:focus {
    border-color: map-get($brand, orange);

    .sak-text {
      color: map-get($brand, orange);
    }

    path {
      fill: map-get($brand, orange);
    }
  }

  &.disabled {
    pointer-events: none;

    .sak-text {
      color: map-get($grey, base);
    }

    path {
      fill: map-get($grey, base);
    }
  }
}

.nav-link.tertiary {
  .sak-text {
    color: $white;
  }

  svg {
    transition: all 0.4s ease-in;
  }

  &:hover svg {
    transform: translateX(5px);
  }
}
