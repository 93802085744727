@import '../../../../styles/breakpoints';

.company-data-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;

  @include tablet-breakpoint {
    display: flex;
    flex-direction: column;
  }

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
  }

  > div > h2:last-of-type {
    margin-top: 40px;
  }

  .company-data-form {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;

    .company-address {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;

      > div:first-child {
        grid-column: 1 / 5;
      }

      > div:last-child {
        grid-column: 2 / 5;
      }
    }
  }

  > button {
    justify-self: self-start;
  }
}
