@import '../../styles/colors';
@import '../../styles/breakpoints';
@import '../../styles/mixin';

.tooltip-header-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-bottom: 24px;
  width: fit-content;

  @include mobile-breakpoint {
    display: flex;
    justify-content: space-between;
    align-content: center;
    min-width: 220px;
  }

  .tooltip-text-wrapper {
    top: 100%;
    right: 50%;
    margin-left: 40px;
    min-width: 320px;
    transform: translateX(50%);

    @include mobile-breakpoint {
      min-width: 220px;
      right: -10%;
      transform: translateX(0);
    }
  }

  .info-icon:hover + .tooltip-text-wrapper::before {
    @include arrow-top;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mobile-breakpoint {
      left: auto;
      right: -12px;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    position: absolute;
  }
}
