@import '../../styles/colors';

.comment-box {
  position: relative;

  > div:first-child {
    height: 164px;
  }

  &:focus-within .submit-comment circle {
    fill: map-get($brand, orange);
  }

  .submit-comment {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
}
