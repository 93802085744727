@import '../../styles/colors';

.checkbox-list-wrapper {
  .checkbox-wrapper {
    width: 100%;
  }

  .select-all-label.checkbox-wrapper {
    border-bottom: 1px solid map-get($brand, green);
  }
}
