$brand: (
  green: #2b453f,
  beige: #edebe7,
  orange: #ff5d05
);

$green: (
  0: #2b453f,
  200: #264d44,
  400: #225448,
  600: #1f6655,
  800: #5a726c,
  1000: #95a29f
);

$beige: (
  250: #f0ebe6,
  500: #e0deda,
  750: #d4d2cd,
  1000: #c9c7c3
);

$orange: (
  200: #ff5d05,
  400: #ff7226,
  600: #ff8340,
  800: #ff8b4d,
  1000: #ffefe6
);

$grey: (
  base: #aeaeae,
  semi: #e7e7e7,
  light: #fcfcfc
);

$sepLegend: (
  blue: #00c5ff,
  dark-blue: #192f9f,
  yellow: #ffaa01,
  orange: #ff5501,
  red: #a80000
);

$state: (
  inactive: #dbdbda,
  inactive-font: #b6b6b6,
  hover-beige: #f0ebe6
);

$status: (
  info: #3f3f3f,
  success: #1f6655,
  error: #db3939
);

$black: #000;
$white: #fff;

$light-orange: #ffc5a6;

// to implement: 1.import colors  2.background-color: map-get($green, 200);
