@import '../../../../../styles/colors';
@import '../../../../../styles/mixin';

.offer_requested,
.check_open,
.loan_open {
  color: map-get($status, info);
}

.offer_received,
.check_done {
  color: map-get($status, success);
}

.check_approve_requested {
  color: map-get($orange, 400);
}

.check_failed {
  color: map-get($status, error);
}
