@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.personal-data-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include tablet-breakpoint {
    gap: 24px;
  }
}

.personal-data-title {
  margin-bottom: 40px;
}
