@import '../../../styles/colors';
@import '../../../styles/mixin';

.table-wrapper {
  display: flex;
  flex-direction: column;

  .table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 16px;
      height: 80px;
    }

    .table-row-head {
      > th {
        border-bottom: 1px solid map-get($green, 1000);
        text-align: left;
      }
    }
  }

  tbody {
    border-bottom: 1px solid map-get($beige, 500);
    transition: 0.3s ease-out;
    align-items: center;

    > * {
      white-space: pre-wrap;
    }

    tr {
      background-color: $white;
      border-bottom: 1px solid map-get($beige, 500);
    }
  }

  &.hover-effect tbody tr:hover {
    cursor: pointer;
    background-color: map-get($beige, 500);
    @include backgroundOpacity($white, 0.5);
  }
}
