@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.dropdown-list-wrapper {
  border-radius: 4px;
  border: 2px solid map-get($beige, 1000);
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  @include mobile-breakpoint {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  @include tablet-breakpoint {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  @include box-shadow-inputs;
  background-color: $white;

  &.no-value .dropdown-list-heading {
    @include fontColorOpacity(map-get($brand, green), 0.5);
  }

  .dropdown-list-items {
    position: absolute;
    top: 100%;
    left: -2px;
    right: -2px;
    border: 2px solid map-get($beige, 1000);
    z-index: 1000;
    display: none;
    background-color: $white;
    padding: 16px;

    .dropdown-list-item {
      display: block;

      &:hover {
        background-color: map-get($beige, 250);
      }

      &.active {
        background-color: map-get($beige, 250);
      }
    }
  }

  path {
    fill: map-get($brand, green);
  }
}

.dropdown-list-wrapper.open {
  .dropdown-list-items {
    display: block;
  }
}
