.table-row-body {
  td:last-child {
    padding: 0 0;
  }
}

.credit-terms-button {
  margin-top: 20px;
  align-self: flex-start;
}
