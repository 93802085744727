@import '../../../styles/colors';

.radio-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid map-get($beige, 1000);
  cursor: pointer;

  &.secondary {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 20px;
    border: 0;
  }

  label {
    cursor: pointer;
  }

  .radio {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 50%;
    background: $white;
    border: 4px solid $white;
    box-shadow: 0 0 0 3px map-get($brand, green);
    transition: all 0.4s ease;
    cursor: pointer;

    &:checked {
      background: map-get($brand, orange);
      box-shadow: 0 0 0 3px map-get($brand, orange);
    }

    &:disabled {
      box-shadow: 0 0 0 3px map-get($beige, 750);

      &:checked {
        background: map-get($beige, 750);
      }
    }
  }
}
