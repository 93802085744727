@import '../../../styles/colors';
@import '../../../styles/mixin';

.partner-projects-overview-wrapper {
  display: flex;
  flex-direction: column;

  > .filters-wrapper {
    display: flex;
    gap: 16px;
    margin-top: 43px;

    .search-form {
      width: 500px;
    }

    .input-container {
      width: 300px;
    }

    .price-range-button {
      height: 100%;
    }
  }

  .table-wrapper {
    margin-top: 16px;
  }

  .pagination {
    margin-top: 16px;
    align-self: flex-end;
  }

  .project-status {
    color: map-get($status, info);

    &.accepted,
    .offer_completed {
      color: map-get($status, success);
    }

    &.declined,
    .expired {
      color: map-get($status, error);
    }

    &.created {
      color: map-get($orange, 200);
    }
  }
}
