@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.sep-search-decoration {
  display: flex;
  position: absolute;
  padding-left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;

  svg {
    margin: auto;

    path {
      fill: rgba(map-get($brand, green), 0.5);
    }
  }
}

.autocomplete-container {
  height: 78px;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  font-family: 'Poppins-Medium';
  background-color: $white;
  position: relative;
  border-radius: $radius-small;

  &:has(.address-input:focus) {
    .autocomplete-suggestions {
      opacity: 100%;
    }
  }

  @include tablet-breakpoint {
    height: 80px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }

  &.disabled {
    pointer-events: none;
    background-color: map-get($grey, 1000);
  }

  .address-input {
    width: 100%;
    padding: 20px 20px 20px 50px;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    color: map-get($brand, green);
    background-color: transparent;
    border: 2px solid map-get($beige, 1000);
    border-radius: $radius-small;

    &:focus {
      outline: none;
      border: 2px solid map-get($brand, orange);
      border-radius: $radius-small;
      border-bottom-color: map-get($beige, 1000);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;

      .sep-search-decoration {
        path {
          fill: map-get($brand, green);
        }
      }
    }
  }
}

.autocomplete-suggestions {
  opacity: 0%;
  z-index: 1000;
  background-color: $white;
  outline: none;
  border: 2px solid map-get($brand, orange);
  border-radius: $radius-small;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;

  & .suggestion-item:first-child {
    border-top: 2px solid map-get($beige, 1000);
  }
}

.suggestion-item {
  cursor: pointer;
  padding: 10px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
