@import '../../styles/colors';

.contract-details-wrapper {
  > h2 {
    margin-bottom: 32px;
  }
  
  .contract-details {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 30px 40px;

    .contract-details-header {
      display: flex;
      justify-content: space-between;
    }

    .contract-details-main {
      display: grid;
      column-gap: 40px;
      row-gap: 15px;
      grid-template-columns: 1fr 5fr;
      padding-block: 24px;

      .contract-documents {
        width: 500px;
      }
    }
  }
}
