@import '../../../styles/colors';

.log-item-wrapper {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  gap: 10px;

  .log-icon {
    height: 24px;
    width: 24px;
  }

  .log {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;

    .log-info {
      display: flex;
      justify-content: space-between;

      .log-author-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .log-author {
          color: map-get($green, 1000);
        }

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
