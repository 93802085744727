@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';
@import '../../styles/mixin';

.button {
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.4s ease-out;

  &:disabled {
    pointer-events: none;
  }

  &.primary {
    background-color: map-get($brand, orange);
    border: 2px solid map-get($brand, orange);

    & .sak-text {
      color: $white;
    }

    path {
      fill: $white;
    }

    &:hover {
      background-color: map-get($orange, 600);
      border-color: map-get($orange, 600);
    }

    &:focus {
      background-color: map-get($brand, green);
      border-color: map-get($brand, green);
    }

    &:disabled {
      background-color: map-get($state, inactive);
      border-color: map-get($state, inactive);
      color: map-get($state, inactive-font);

      & .sak-text {
        color: map-get($state, inactive-font);
      }

      path {
        fill: map-get($state, inactive-font);
      }
    }
  }

  &.secondary {
    border: 2px solid map-get($orange, 1000);
    background-color: map-get($orange, 1000);

    & .sak-text {
      transition: color 0.4s ease-out;
      color: map-get($brand, green);
    }

    path {
      fill: map-get($brand, green);
      transition: fill 0.4s ease-out;
    }

    &:hover {
      border-color: map-get($brand, orange);
    }

    &:focus {
      border-color: map-get($brand, green);
      background-color: map-get($orange, 1000);

      path {
        fill: map-get($brand, green);
      }
    }

    &:disabled {
      background-color: transparent;
      border-color: map-get($state, inactive);

      .sak-text {
        color: map-get($state, inactive-font);
      }

      path {
        fill: map-get($grey, inactive-font);
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    transition: all 0.4s ease-out;

    &:hover {
      border-color: map-get($green, 600);

      .sak-text {
        color: map-get($green, 600);
      }

      svg {
        path {
          fill: map-get($green, 600);
        }
      }
    }

    &:focus {
      color: map-get($brand, orange);
      border-color: map-get($brand, orange);

      .sak-text {
        color: map-get($brand, orange);
      }

      svg {
        path {
          fill: map-get($brand, orange);
        }
      }
    }

    &:disabled {
      border-color: map-get($beige, 500);
      .sak-text {
        color: map-get($grey, base);
      }

      svg {
        path {
          fill: map-get($grey, base);
        }
      }
    }
  }

  &.small {
    padding: 5px 20px;
  }

  &.medium {
    padding: 8px 30px;
  }

  &.large {
    padding: 12px 24px;

    @include mobile-breakpoint {
      padding: 10px 15px;
    }

    @include mobile-breakpoint {
      padding: 10px;
    }
  }

  &.left {
    flex-direction: row-reverse;
  }
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    vertical-align: middle;
  }
}
