@import '../../styles/colors';
@import '../../styles/breakpoints';

.project-status-field-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    min-width: 32px;
  }

  @include tablet-breakpoint {
    text-align: center;
  }

  @include mobile-breakpoint {
    text-align: center;
  }

  .status.sak-text {
    color: map-get($status, info);
    padding: 0 15px;

    &.kycFailed,
    &.fourEyeCheckFailed {
      color: map-get($status, error);
    }
  }

  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: map-get($status, info);
    border-radius: 50%;

    &.status-kycFailed,
    &.status-fourEyeCheckFailed {
      background-color: map-get($status, error);
    }

    &.status-kycPassed,
    &.status-creditCheckCompleted {
      background-color: map-get($status, success);
    }

    @include mobile-breakpoint {
      display: none;
    }
  }
}
