@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.mes-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: auto;

  &.disabled {
    pointer-events: none;
  }
}

.map-container {
  width: 100%;
  height: 480px;
  position: relative;

  @include tablet-breakpoint {
    height: 385px;
  }

  @include mobile-breakpoint {
    height: 385px;
  }
}

.map {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.map-spinner-container {
  z-index: 99;
  background-color: map-get($brand, beige);
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: 0;
}

.map-spinner {
  border: 5px solid map-get($brand, beige);
  border-top-color: map-get($brand, green);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.ol-zoom {
  flex-direction: column;
  width: 32px;
  display: flex;
}

.ol-zoom-in {
  background: $white url('../../../assets/Icons/zoom_in.svg') 50%/32px 32px no-repeat;
  border: none;
  border-radius: $radius-small;
  width: 32px;
  height: 32px;
  margin-top: 4px;
  font-size: 0;
  cursor: pointer;
}

.ol-zoom-out {
  background: $white url('../../../assets/Icons/zoom_out.svg') 50%/32px 32px no-repeat;
  border: none;
  border-radius: $radius-small;
  width: 32px;
  height: 32px;
  font-size: 0;
  cursor: pointer;
}

.ol-zoom-in,
.ol-zoom-out {
  margin-left: 4px;
}

.ol-zoom-in:last-child,
.ol-zoom-out:last-child {
  margin-bottom: 0;
}
