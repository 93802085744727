@import '../../styles/breakpoints';
@import '../../styles/colors';

.credit-check-wrapper {
  display: grid;
  justify-content: start;
  grid-template-columns: minmax(auto, 940px) auto;
  column-gap: 140px;

  @include lg-breakpoint {
    column-gap: 40px;
  }

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
  }

  > h1 {
    margin-bottom: 40px;
    display: block;
    grid-column: 1 / 3;
  }

  > .wizard-wrapper {
    grid-column: 2 / 3;
    grid-row: 2;
  }

  .credit-check-form-wrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    grid-row: 2;

    .credit-check-form {
      display: flex;
      flex-direction: column;
      gap: 40px;

      > div {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .credit-check-personal-details {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;

        @include mobile-breakpoint {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      div {
        > .dropdown-list-wrapper.open .dropdown-list-items {
          max-height: 300px;
          overflow: scroll;
        }
      }

      .resident-wrapper {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        gap: 40px;

        @include mobile-breakpoint {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      .address-action-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .account-owner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        column-gap: 40px;

        span:first-child {
          grid-column: 1 / -1;
        }

        @include mobile-breakpoint {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
    }

    .beneficial-owner-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 40px 0;
    }
  }

  .living-situation-wrapper {
    display: flex;
    flex-direction: column;

    > div {
      > span {
        margin-bottom: 40px;
        display: inline-block;
      }
    }
  }
}

.transport-choices-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .transport-checkbox-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @include mobile-breakpoint {
      gap: 12px;
      .checkbox-wrapper {
        gap: 12px;
        .sak-text.title.medium {
          font-size: 16px;
        }
      }
    }
  }
}

.church-obligation-wrapper {
  display: flex;
  gap: 200px;

  @include mobile-breakpoint {
    gap: 100px;
  }
}

.contact-here {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 4px;

  > svg {
    path {
      fill: map-get($brand, green);
    }
  }
}

.highlight {
  color: map-get($status, error) !important;

  > span {
    color: map-get($status, error) !important;
  }

  path {
    fill: map-get($status, error);
  }

  input,
  div.input,
  .placeholder.sak-text,
  label,
  input::placeholder {
    color: map-get($status, error) !important;
  }
}
