@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.kyc-wrapper {
  display: flex;
  gap: 20px;
  align-self: flex-start;
  max-width: 680px;
  padding: 40px;
  background-color: $white;
  @include box-shadow-inputs;

  @include mobile-breakpoint {
    padding: 20px;
  }

  @include xs-breakpoint {
    width: 100%;
  }

  @include tablet-breakpoint {
    padding: 24px;
  }

  .id-icon {
    height: 160px;
    width: 160px;
  }

  .kyc-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 70%;

    > button {
      width: 240px;
    }

    @include mobile-breakpoint {
      width: 100%;

      > button {
        width: auto;
      }
    }

    @include tablet-breakpoint {
      width: 100%;
    }

    > .button {
      width: 100%;
    }
  }

  .kyc-status {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    span:nth-child(2) {
      display: flex;
      align-items: center;
    }

    span:last-child {
      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 6px;
        flex-shrink: 0;
      }
    }

    .kyc-pending {
      color: map-get($grey, base);

      &::before {
        background-color: map-get($grey, base);
      }
    }

    .kyc-unsuccessful {
      color: map-get($status, error);

      &::before {
        background-color: map-get($status, error);
      }
    }

    .kyc-successful {
      color: map-get($status, success);

      &::before {
        background-color: map-get($status, success);
      }
    }
  }
}
