@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.info-field-wrapper {
  padding: 24px 32px;
  background-color: $white;
  display: flex;
  align-items: center;

  @include mobile-breakpoint {
    padding: 12px 16px;
  }

  .info-text {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    > p {
      flex: 1;
      text-align: end;
    }

    @include mobile-breakpoint {
      flex-direction: column;
      align-items: flex-start;
    }

    &.v2 {
      align-items: start;
      flex-direction: column;
    }
  }

  .info-icon {
    position: absolute;
  }

  @include xl-breakpoint {
    padding: 24px;
  }

  @include xxl-breakpoint {
    padding: 24px;
  }

  .info-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;
    position: relative;

    .button {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .info-field-wrapper.disabled {
    .no-value {
      color: map-get($beige, 1000);
    }

    .info-icon path {
      fill: map-get($beige, 1000);
    }

    .info-icon:hover + .tooltip {
      display: none;
    }
  }
}
