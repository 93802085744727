@import '../../../../styles/colors';

.contract-document {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    color: map-get($brand, orange);
  }

  > svg {
    path:first-child {
      fill: map-get($brand, orange);
    }
  }

  &:hover {
    cursor: pointer;
    > p {
      color: map-get($orange, 600);
    }

    > svg {
      path:first-child {
        fill: map-get($orange, 600);
      }
    }
  }
}
