@import '../../../../styles/breakpoints';

.partner-info-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 80px;

  @include tablet-breakpoint {
    flex-direction: column;
  }

  @include mobile-breakpoint {
    flex-direction: column;
  }

  .partner-data {
    width: 50%;

    @include tablet-breakpoint {
      width: 100%;
    }

    @include mobile-breakpoint {
      width: 100%;
    }

    .input-container {
      margin-top: 24px;
      width: 100%;
    }

    .button {
      margin-top: 20px;
    }
  }

  .partner-product {
    width: 50%;

    @include tablet-breakpoint {
      width: 100%;
    }

    @include mobile-breakpoint {
      width: 100%;
    }

    .file-item {
      margin-top: 24px;
    }
  }
}
