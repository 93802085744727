@import '../../styles/colors';

.price-range-wrapper {
  position: relative;

  .price-range-list {
    display: none;
    position: absolute;
    bottom: -2%;
    left: 0;
    z-index: 2;
    transform: translateY(100%);
    background-color: $white;
    padding: 8px 0;
    width: max-content;
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgba(20, 50, 80, 0.12), 0px 1px 10px 0px rgba(3, 12, 37, 0.1),
      0px 2px 5px 0px rgba(3, 12, 37, 0.12);

    .radio-wrapper {
      flex-direction: row-reverse;
      gap: 12px;
    }

    .price-range-radio {
      padding: 10px 16px;
    }
  }
}

.price-range-wrapper.show {
  .price-range-button {
    border-color: map-get($brand, green);
    background-color: transparent;
  }
  .price-range-list {
    display: block;
  }
}

.price-range-button {
  padding: 18px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
  background-color: $white;
  border-radius: 4px;
  border: 2px solid map-get($beige, 1000);
  cursor: pointer;
  transition: all 0.4s ease-out;
}
