@import '../../styles/colors';
@import '../../styles/breakpoints';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 192px;
    height: 32px;
    cursor: pointer;

    @include tablet-breakpoint {
      width: 132px;
      height: 22px;
    }

    @include mobile-breakpoint {
      width: 96px;
      height: 16px;
    }
  }

  .burger-icon,
  .close-icon {
    display: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  @include tablet-breakpoint {
    .burger-icon {
      display: inline-block;
    }

    .navigation {
      .navigation-list {
        position: absolute;
        padding: 0 40px 24px 40px;
        background-color: map-get($brand, beige);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        top: -2000px;
        left: 0;
        width: 100%;
        height: max-content;
        z-index: 2;
        transition: top 0.6s ease-in-out;

        > li:nth-child(1) {
          border-top: 1px solid map-get($beige, 750);
          width: 100%;
          display: flex;

          & a {
            margin-top: 14px;
          }
        }

        > li:nth-child(3) {
          & a {
            margin-bottom: 34px;
          }
        }

        > li:last-child {
          width: 100%;
          border-top: 1px solid map-get($beige, 750);

          .button {
            width: 100%;
            margin-top: 14px;
          }
        }
      }
    }

    .navigation.open {
      .navigation-list {
        top: 100%;
      }

      .close-icon {
        display: inline-block;
      }

      .burger-icon {
        display: none;
      }
    }
  }

  @include mobile-breakpoint {
    .burger-icon {
      display: inline-block;
    }

    .navigation {
      .navigation-list {
        position: absolute;
        padding: 0 24px;
        background-color: map-get($brand, beige);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        top: -2000px;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        transition: top 0.6s ease-in-out;
        overflow: hidden;

        > li:nth-child(1) {
          border-top: 1px solid map-get($beige, 750);
          width: 100%;
          display: flex;

          & a {
            margin-top: 14px;
          }
        }

        > li:nth-child(3) {
          & a {
            margin-bottom: 34px;
          }
        }

        > li:last-child {
          width: 100%;
          border-top: 1px solid map-get($beige, 750);

          .button {
            width: 100%;
            margin-top: 14px;
          }
        }
      }
    }

    .navigation.open {
      .navigation-list {
        top: 100%;
      }

      .close-icon {
        display: inline-block;
      }

      .burger-icon {
        display: none;
      }
    }
  }
}
