@import '../../styles/breakpoints';

.notification-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  @include xs-breakpoint {
    width: 100%;
  }

  .secondary-text {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .notification-icon {
    align-self: center;
    height: 188px;
    width: 188px;

    @include tablet-breakpoint {
      height: 128px;
      width: 128px;
    }

    @include mobile-breakpoint {
      height: 88px;
      width: 88px;
    }
  }
}
