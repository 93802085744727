@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.tab-title {
  button {
    padding: 10px 24px;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid transparent;
    transition: 400ms ease-out;

    @include xs-breakpoint {
      padding: 0;
      width: fit-content;
    }
  }
}

.tab-title[class~='active'] {
  button {
    border-color: map-get($brand, orange);

    span {
      color: map-get($brand, orange);
    }
  }
}

.tab-title[class~='disabled'] {
  pointer-events: none;

  span {
    color: map-get($grey, base);
  }
}
