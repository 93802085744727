.kremo-table-wrapper {
  .table-wrapper:nth-child(3) {
    thead tr {
      width: 100%;
      border-bottom: 1px solid #95a29f;
    }
  }

  .button {
    align-self: flex-end;
  }
}

.wrapper:has(.kremo-table-wrapper) {
  width: 90%;
}
