@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.zek-table-wrapper,
.personal-details-table-wrapper,
.credit-offer-table-wrapper,
.financial-valuation-table-wrapper,
.comment-preview-wrapper {
  .button {
    align-self: flex-end;
  }
}

.wrapper:has(.zek-table-wrapper) {
  width: 90%;

  @include mobile-breakpoint {
    width: 100vw;
  }
}

.wrapper:has(.personal-details-table-wrapper) {
  width: 40%;

  @include mobile-breakpoint {
    width: 100%;
  }
}

.data-confirmation-button {
  width: 100%;
}

.user-project-data-wrapper {
  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
    column-gap: 40px;
    margin-bottom: 40px;

    @include tablet-breakpoint {
      grid-template-columns: 1fr;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
    }
  }
}

.project-log {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
  }
}

.review-instructions-text {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  margin-bottom: 32px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 40px;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 40px;
  }
}

.new-log-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 38px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 38px;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 32px;
  }

  .file-selector-wrapper {
    margin-top: 32px;
  }
}

.project-review-heading {
  margin-top: 32px;
  margin-bottom: 32px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: map-get($brand, green);
  }
}

.comment-submission-action-wrapper {
  display: flex;
  gap: 24px;
  margin-top: 32px;

  > button {
    width: 100%;
  }
}
