@import '../../../styles/breakpoints';

.offer-edit {
  & > * {
    margin-bottom: 40px;
  }

  .details-edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    @include tablet-breakpoint {
      grid-template-columns: 1fr;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
    }
  }

  .partner-action {
    cursor: pointer;
  }

  .upload-file-wrapper {
    margin-top: 40px;
  }

  .file-item {
    margin-top: 40px;
  }
}
