@import '../../../styles/colors';
@import '../../../styles/variables';

.projects-overview-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .pagination {
    align-self: flex-end;
    margin-top: auto;
  }
}
