@import '../../styles/colors';
@import '../../styles/breakpoints';

.contract-item-wrapper {
  max-width: 66%;
  padding: 18px 36px;
  background-color: $white;

  .contract-item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include mobile-breakpoint {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 10px;
    }

    @include tablet-breakpoint {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 10px;
    }
  }
}
