@import '../../styles/colors';

.chip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 4px;
  border: 1px solid map-get($brand, orange);
  border-radius: 100px;
  cursor: pointer;

  .chip-label {
    padding: 4px 6px;
    color: map-get($green, 800);
  }

  .chip-delete-icon {
    cursor: pointer;
    z-index: 99;
  }
}

.chip-selected {
  border: 2px solid map-get($brand, orange);
  cursor: auto;
}
