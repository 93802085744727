@import '../../styles/colors';
@import '../../styles/mixin';

.progress-bar {
  width: 100%;
  height: 12px;
  @include backgroundOpacity(map-get($green, 1000), 0.1);
  overflow: hidden;
  border-radius: 16px;
}

.progress-bar-inner {
  height: 100%;
  width: 0;
  background-color: map-get($brand, orange);
  transition: width 0.2s;
}

.progress-bar .progress-bar-inner {
  width: 100%;
}
