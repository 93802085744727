@import '../../../styles/colors';
@import '../../../styles/variables';

.modal-wrapper {
  display: flex;
  flex-direction: column;

  .steps-wrapper {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    > button {
      flex: 1;
    }
  }
}
