@import '../../../../styles/breakpoints';

.partner-project-details-wrapper {
  display: grid;
  column-gap: 40px;
  grid-template-columns: 1.4fr 1fr;
  margin-top: 40px;

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
  }

  .map-container {
    margin-bottom: 32px;
  }

  .tooltip-header-wrapper {
    margin-top: 40px;
  }

  .modules-extras-wrapper {
    margin-bottom: 32px;
  }

  .project-info > .button {
    margin-top: 32px;
  }
}
