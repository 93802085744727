@import '../../styles/breakpoints';
@import '../../styles/colors';

.start-financing-wrapper {
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;

  @include mobile-breakpoint {
    width: 100%;
  }

  @include tablet-breakpoint {
    width: 100%;
  }

  > svg {
    width: 85px;
    height: 85px;
  }

  > button {
    min-width: 270px;
  }

  .contact-link {
    transition: all 0.4s ease-out;
    color: map-get($brand, green);
    border-bottom: 1px solid transparent;

    &:hover {
      cursor: pointer;
      color: map-get($green, 600);

      path {
        fill: map-get($green, 600);
      }
    }
  }
}
