@import '../../../styles/colors';
@import '../../../styles/mixin';
@import '../../../styles/breakpoints';

.rating {
  flex-basis: 50%;
  border-right: 1px solid $black;
  position: relative;
  display: flex;
  flex-direction: column;

  @include lg-breakpoint {
    border-right: 0;
  }

  @include tablet-breakpoint {
    border-right: 0;
  }

  @include mobile-breakpoint {
    border-right: 0;
  }

  h3 {
    margin-bottom: 25px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
    font-weight: 500;
  }

  label {
    margin-top: 55px;
    align-self: center;
  }
}

.rating-list {
  margin-top: 54px;
  align-self: center;

  li {
    display: flex;
    gap: 10px;
    font-size: 14px;
    align-items: center;
  }
  li:last-child {
    margin-left: 30px;
    margin-top: 25px;
  }
}

.upload-button {
  align-self: center;
}
