.contract-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  width: max-content;

  .contract-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
    width: 100%;

    .dropdown-list-wrapper {
      margin-top: 6px;
    }

    .contract-files {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
  }

  .contract-signatures {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 32px;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
    }
  }
}
