@import '../../styles/colors';
@import '../../styles/breakpoints';

.icon-text-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .text-icon {
    width: 24px;
    height: 24px;
  }
}
