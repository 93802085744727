@import '../../../styles/breakpoints';
@import '../../../styles/mixin';

.partners-dashboard-cards {
  @include grid-equal-cols(40px, 3, 420px);
  margin-top: 40px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include tablet-breakpoint {
    @include grid-equal-cols(24px, 2, 260px);
  }

  .profile-card-icon {
    align-self: flex-start;
    width: 96px;
    height: 96px;
  }
}

.partner-dashboard-details {
  display: flex;
  justify-content: space-between;
  > * {
    display: flex;
    align-items: center;
  }
}
