@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.navigation {
  .navigation-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;

    @include xxl-breakpoint {
      gap: 36px;
    }

    @include xl-breakpoint {
      gap: 24px;
    }

    @include lg-breakpoint {
      gap: 16px;
    }

    .login-button {
      min-width: 310px;
    }

    .logout-button {
      min-width: 225px;
    }

    .logged-user-name {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 25px;
        height: 25px;

        @include tablet-breakpoint {
          width: 20px;
          height: 20px;
        }

        @include mobile-breakpoint {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
