@font-face {
  font-family: 'DM Serif';
  src: url('./fonts/DmSerif/DMSerifText-Regular.eot');
  src: url('./fonts/DmSerif/DMSerifText-Regular.woff2') format('woff2'),
    url('./fonts/DmSerif/DMSerifText-Regular.woff') format('woff'),
    url('./fonts/DmSerif/DMSerifText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins/Poppins-Bold.eot');
  src: url('./fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
    url('./fonts/Poppins/Poppins-Bold.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./fonts/Poppins/Poppins-Regular.eot');
  src: url('./fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
    url('./fonts/Poppins/Poppins-Regular.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./fonts/Poppins/Poppins-Medium.eot');
  src: url('./fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
    url('./fonts/Poppins/Poppins-Medium.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}
