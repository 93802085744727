@import '../../../styles/colors';
@import '../../../styles/mixin';

.currency-link-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 16px;
  min-height: 80px;
  border: 2px solid transparent;

  &:focus-within {
    border-color: map-get($brand, orange);
  }

  input {
    height: 100%;
    width: 100%;
    outline: 0;
    border: none;
    background-color: transparent;
    font-family: 'Poppins-Medium';
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: map-get($brand, green);

    padding-left: 20px;
    z-index: 99;
  }
}

.currency-link-input-wrapper.right {
  flex-direction: row-reverse;

  input {
    padding-left: 0;
  }

  > span {
    flex-basis: 100%;
    padding-right: 20px;
  }
}
