@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';

.offers-wrapper {
  .heading-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include mobile-breakpoint {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .button {
      background-color: map-get($status, error);
    }
  }

  .heading {
    margin-bottom: 50px;

    @include mobile-breakpoint {
      text-align: center;
    }

    @include tablet-breakpoint {
      margin-bottom: 20px;
    }

    @include mobile-breakpoint {
      margin-bottom: 20px;
    }
  }
}

.map-container {
  height: 480px;
  width: 100%;
  margin-bottom: 24px;
}

.offer-tab-wrapper {
  display: flex;
  flex-direction: column;

  .map-wrapper {
    margin-top: 32px;

    h3 {
      margin-bottom: 32px;
    }

    img {
      height: 300px;
    }
  }

  .partners-offer-wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    column-gap: 40px;

    @include tablet-breakpoint {
      grid-template-columns: 1fr;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
    }

    .details .field .info-text {
      flex-direction: column;
      align-items: start;
    }

    main {
      isolation: isolate;

      .offer-financial-preview {
        @include tablet-breakpoint {
          margin-top: 30px;
        }

        @include mobile-breakpoint {
          margin-top: 30px;
        }
      }
    }
  }
}
