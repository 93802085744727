@import '../../../../../styles/colors';

.comment-preview-wrapper {
  .comment-description {
    display: flex;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 68px;
    border-bottom: 1px solid map-get($green, 1000);

    span {
      display: block;
    }

    .comment-person {
      width: min-content;
      height: min-content;
      background-color: $light-orange;
      border-radius: 50%;
      padding: 10px;
    }

    .comment-text {
      color: map-get($green, 1000);
    }
  }

  .comment-preview-action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
}
