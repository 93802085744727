@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.wizard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @include mobile-breakpoint {
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
    justify-content: space-between;
    gap: 2px;

    .wizard-item {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 2px;
    }

    > div:last-child {
      flex: 0;
    }
  }

  .wizard-step {
    display: flex;
    align-items: center;
    cursor: pointer;

    .checkbox-wrapper.disabled {
      .checkbox {
        background-color: map-get($status, success);
        border-color: map-get($status, success);
      }
      * {
        cursor: pointer;
      }
    }

    @include mobile-breakpoint {
      .step-title {
        display: none;
      }
    }
  }

  .wizard-separator {
    width: 12px;
    height: 48px;
    border-radius: 16px;
    @include backgroundOpacity(map-get($green, 600), 0.1);
    margin: 0 14px;

    @include mobile-breakpoint {
      width: 100%;
      height: 12px;
      margin: 0;
    }
  }
}
