@import '../../styles/breakpoints';

.tabs {
  > ul {
    list-style: none;
    display: flex;
    padding: 0;
    position: relative;
    margin-bottom: 40px;

    li:first-child button {
      margin-left: 0;
    }

    li:last-child {
      &::after {
        display: none;
      }
    }

    @include mobile-breakpoint {
      align-items: center;
      gap: 12px;
    }
  }
}

.tabs.hide {
  ul {
    li {
      display: none;
    }
  }
}
