@import '../../styles/colors';
@import '../../styles/breakpoints';

.financing-plan-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .tooltip {
    background-color: inherit;
    justify-content: flex-start;
    gap: 10px;
    padding-left: 0px;

    &.info-field-wrapper {
      padding: 0 0;
      margin-bottom: 32px;
    }
  }

  .financing-plan {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    background-color: $white;

    .financing-plan-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .deposit-input {
        display: flex;
        align-items: center;
        gap: 12px;

        .edit-deposit {
          cursor: pointer;

          &.disabled {
            pointer-events: none;

            path {
              fill: map-get($beige, 1000);
            }
          }
        }
        > input {
          border: none;
          height: 32px;
          text-align: right;
          font-weight: 500;
          font-size: 24px;
          font-family: 'Poppins-Bold';

          &:focus {
            outline: none;
          }

          @include tablet-breakpoint {
            font-size: 20px;
            line-height: 24px;
          }

          @include mobile-breakpoint {
            font-size: 18px;
          }
        }
      }
    }

    .monthly-rate {
      display: flex;
      gap: 20px;
      align-items: center;

      span:first-child {
        flex: 1;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .monthly-rate-ammount {
        text-align: right;
      }
    }
  }

  .financing-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.monthly-rate-tooltip {
  .tooltip-text-wrapper {
    left: -190px;
  }

  .info-icon:hover + .tooltip-text-wrapper::before {
    left: 190px;
  }
}
