@import '../../styles/colors';
@import '../../styles/breakpoints';

.contact-box-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  padding: 18px 32px;
  background-color: $white;

  .user-contact-box-wrapper {
    > span {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      width: fit-content;
    }

    > span:nth-child(2),
    > span:nth-child(3) {
      cursor: pointer;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    path {
      fill: map-get($brand, green);
    }
  }
}
