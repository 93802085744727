@import '../../../../../styles/breakpoints';

.cantons-select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile-breakpoint {
    margin-top: 40px;
  }

  .cantons-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
