@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.security-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 660px;

  .change-pass {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .button {
      align-self: flex-start;

      @include tablet-breakpoint {
        width: 100%;
      }

      @include mobile-breakpoint {
        width: 100%;
      }
    }

    @include mobile-breakpoint {
      width: 100%;
    }

    @include tablet-breakpoint {
      width: 100%;
    }

    @include lg-breakpoint {
      width: 100%;
    }

    .change-pass-form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      > button.button.large {
        align-self: flex-start;
        padding: 18px 32px;
      }
    }
  }

  .two-factor-auth-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .two-factor-auth {
      background-color: $white;
    }
    .switch-wrapper .switch-content .sak-text.title.large {
      font-size: 20px;
      line-height: 28px;

      @include tablet-breakpoint {
        font-size: 16px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 15px;
      }
    }
  }
}

.two-fa-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
