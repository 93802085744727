@import '../../../styles/colors';
@import '../../../styles/mixin';

.range-slider {
  width: 100%;
  height: 12px;
  border-radius: 12px;
  @include backgroundOpacity(map-get($green, 600), 0.1);
  display: flex;
  align-items: center;

  .range-slider-inner {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 100%;
    height: 12px;
    margin: 0px;
    border-radius: 12px;
    background: linear-gradient(map-get($brand, orange), map-get($brand, orange));
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      background-color: $white;
      border: 2px solid map-get($brand, orange);
      border-radius: 24px;
    }

    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      background-color: $white;
      border: 2px solid map-get($brand, orange);
      border-radius: 24px;
    }
  }

  &:hover {
    .range-slider-inner::-webkit-slider-thumb {
      background-color: map-get($green, 600);
      border-color: map-get($green, 600);
    }

    .range-slider-inner::-moz-range-thumb {
      background-color: map-get($green, 600);
      border-color: map-get($green, 600);
    }
  }

  .range-slider-inner:focus::-webkit-slider-thumb {
    background-color: map-get($brand, green);
    border-color: map-get($brand, green);
  }

  .range-slider-inner:focus::-moz-range-thumb {
    background-color: map-get($brand, green);
    border-color: map-get($brand, green);
  }

  .range-slider-inner.disabled {
    background: linear-gradient(map-get($beige, 1000), map-get($beige, 1000));
    background-repeat: no-repeat;
  }

  & .range-slider-inner.disabled::-webkit-slider-thumb {
    background-color: map-get($beige, 1000);
    border-color: map-get($beige, 1000);
  }

  & .range-slider-inner.disabled::-moz-range-thumb {
    background-color: map-get($beige, 1000);
    border-color: map-get($beige, 1000);
  }
}
