@import '../../styles/colors';
@import '../../styles/breakpoints';

.fields {
  display: flex;
  flex-direction: column;
  margin: 32px 0 40px 0;
  container-type: inline-size;

  @include tablet-breakpoint {
    margin-top: 20px;
  }

  @include mobile-breakpoint {
    margin-top: 20px;
  }

  @media only screen and (min-width: #{$lgBreakpoint}px) {
    .info-text {
      h3.sak-text.label.large {
        @container (max-width: 500px) {
          font-size: 18px;
        }
      }

      span.sak-text.headline.medium,
      p.sak-text.headline.medium {
        @container (max-width: 500px) {
          font-size: 22px;
        }
      }
    }
  }

  .finance-wrapper {
    > div:first-child {
      border-bottom: 1px solid map-get($brand, green);
    }
    margin-bottom: 24px;
  }

  .general-data-wrapper {
    margin-top: 24px;
  }
}

.modules-wrapper {
  .extras-heading {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
