@import '../../styles/breakpoints';

.upload-offer-edit {
  .main {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 7fr 5fr;
    column-gap: 30px;

    @include xl-breakpoint {
      grid-template-columns: 6fr 4fr;
    }

    @include lg-breakpoint {
      grid-template-columns: 1fr;
    }

    @include tablet-breakpoint {
      grid-template-columns: 1fr;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
    }

    .uploaded-result {
      h3 {
        margin-bottom: 24px;
      }

      .cover {
        margin-top: 16px;
      }

      > label {
        margin-top: 10px;
      }

      & > div:nth-child(5) {
        margin-bottom: 40px;
      }
    }

    .uploaded-offer-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
      margin-top: 10px;
      margin-bottom: 40px;

      @include tablet-breakpoint {
        grid-template-columns: 1fr;
      }

      @include mobile-breakpoint {
        grid-template-columns: 1fr;
      }
    }

    .financial-overview {
      isolation: isolate;
    }
  }
}
