@import '../../styles/colors';
@import '../../styles/breakpoints';

.footer-flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile-breakpoint {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo-wrapper {
    flex-basis: 33%;

    @include mobile-breakpoint {
      border-bottom: 1px solid $white;
    }

    .logo {
      width: 192px;
      height: 32px;
      margin-bottom: 32px;

      @include tablet-breakpoint {
        margin-bottom: 24px;
      }

      @include mobile-breakpoint {
        width: 96px;
        height: 16px;
        margin-bottom: 16px;
      }
    }

    > p {
      color: $white;
      margin-bottom: 48px;
      font-size: 15px;

      @include tablet-breakpoint {
        margin-bottom: 32px;
      }

      @include mobile-breakpoint {
        margin-bottom: 16px;
      }
    }

    .social-list {
      li {
        width: fit-content;

        @include mobile-breakpoint {
          a {
            padding-bottom: 40px;
          }
        }
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .info-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet-breakpoint {
      gap: 16px;
    }

    @include mobile-breakpoint {
      padding: 32px 0;
      border-bottom: 1px solid $white;
      width: 100%;
    }

    .nav-link {
      padding: 0 0;

      > .sak-text {
        font-size: 17px;
      }
    }
  }

  .footer-contact-wrapper {
    display: flex;
    flex-direction: column;

    @include mobile-breakpoint {
      width: 100%;
    }

    .contact-list {
      .sak-text {
        font-size: 17px;
      }

      .contact-list-item {
        width: fit-content;
      }

      @include mobile-breakpoint {
        padding: 32px 0 0 0;
      }
      svg {
        margin-right: 8px;
        vertical-align: middle;
      }

      .contact-list-item {
        border-bottom: 2px solid transparent;
        padding-bottom: 10px;
        transition: all 0.4s ease-out;
        margin-bottom: 48px;

        @include tablet-breakpoint {
          margin-bottom: 32px;
        }

        @include mobile-breakpoint {
          margin-bottom: 26px;
        }

        .sak-text {
          color: $white;
        }

        &:first-child {
          margin-bottom: 24px;

          @include tablet-breakpoint {
            margin-bottom: 16px;
          }
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .contact-list-item {
        cursor: pointer;
      }

      .contact-list-item:hover {
        border-color: map-get($brand, orange);

        .sak-text {
          color: map-get($brand, orange);
        }

        path {
          fill: map-get($brand, orange);
        }
      }
    }

    @include mobile-breakpoint {
      .button {
        width: 100%;
      }
    }
  }
}

footer {
  .copyright-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 54px;
    padding: 16px 0 0 0;
    border-top: 1px solid $white;

    @include mobile-breakpoint {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 32px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 32px;

      @include mobile-breakpoint {
        gap: 0;
        width: 100%;
      }

      @include mobile-breakpoint {
        align-items: flex-start;
        justify-content: space-between;
      }

      @include mobile-breakpoint {
        a {
          padding-top: 16px;
        }
      }
    }

    .sak-text {
      color: $white;
    }
  }
}
