@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.installation-offer-wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  .installation-offer-title {
    margin-bottom: 32px;
  }

  .installation-offer {
    display: grid;
    grid-template-columns: 7fr 5fr;
    column-gap: 30px;

    @include xl-breakpoint {
      grid-template-columns: 5fr 4fr;
    }

    @include lg-breakpoint {
      display: flex;
      flex-direction: column;
    }

    @include tablet-breakpoint {
      display: flex;
      flex-direction: column;
      padding: 24px 0;
    }

    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
      padding: 24px 0;
    }

    .offer-details {
      display: flex;
      flex-direction: column;

      > div {
        margin-bottom: 62px;
      }

      .installation-offer-form-section {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        @include tablet-breakpoint {
          display: flex;
          flex-direction: column;
        }

        @include mobile-breakpoint {
          display: flex;
          flex-direction: column;
        }

        .file-item {
          grid-column: 1 / -1;
        }
      }
    }
    .financial-overview .fields {
      margin: 20px 0 6px;
    }
  }
}
