@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.project-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile-breakpoint {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .button {
    background-color: map-get($status, error);
  }
}

.project-details-tabs {
  > ul {
    margin-top: 40px;
    flex-wrap: wrap;

    li {
      button {
        padding: 10px 24px;
        margin: 0;

        @include mobile-breakpoint {
          padding: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

.project-details-wrapper {
  * > h2 {
    margin-bottom: 32px;

    @include mobile-breakpoint {
      margin-bottom: 16px;
    }
  }

  .project-review {
    margin-bottom: 40px;

    .project-details {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 40px;

      @include tablet-breakpoint {
        grid-template-columns: 1fr;
        gap: 20px;
      }

      @include mobile-breakpoint {
        grid-template-columns: 1fr;
        gap: 20px;
      }

      .project-details-map {
        height: 100%;

        @include tablet-breakpoint {
          height: 200px;
        }

        @include mobile-breakpoint {
          height: 200px;
        }
      }

      .user-info {
        background-color: $white;

        .user-info-row {
          display: flex;
          flex-wrap: wrap;

          > div {
            padding: 18px 32px;
            flex-basis: 50%;

            @include mobile-breakpoint {
              padding: 12px 16px;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
}
