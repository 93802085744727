@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.project-info {
  @include lg-breakpoint {
    margin-bottom: 40px;
  }

  @include tablet-breakpoint {
    margin-bottom: 40px;
  }

  @include mobile-breakpoint {
    margin-bottom: 28px;
  }

  > h3 {
    margin-bottom: 32px;
  }

  .map-container {
    height: 480px;
  }

  > div:nth-child(3) {
    margin-bottom: 24px;
  }

  > div:nth-child(5) {
    margin-bottom: 24px;
  }
  .project-details-info {
    margin-bottom: 24px;

    > div:first-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include mobile-breakpoint {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .project-details-space {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: $white;

    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }
  }

  .tooltip-wrapper {
    svg {
      position: absolute;
    }
  }

  > div:last-child {
    margin-top: 24px;
  }

  > .button {
    margin-top: 40px;

    path {
      fill: $white;
    }
  }

  .project-info-field {
    padding: 18px 32px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      .project-status-field-wrapper {
        padding-top: 4px;
      }
    }
  }

  .contact-box-wrapper {
    margin-top: 32px;
  }

  .project-info-buttons-wrapper {
    display: flex;
    gap: 32px;
  }
}
