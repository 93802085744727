@import '../../../styles/breakpoints';
@import '../../../styles/mixin';

.admin-dashboard-cards {
  @include grid-equal-cols(40px, 3, 420px);
  margin-top: 88px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include tablet-breakpoint {
    @include grid-equal-cols(24px, 2, 260px);
  }

  .profile-card-wrapper:nth-child(3) {
    p {
      letter-spacing: -0.3px;
    }
  }

  .profile-card-icon {
    align-self: flex-start;
    width: 96px;
    height: 96px;
  }
}
