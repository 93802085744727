.partners-overview-wrapper {
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 40px;
  }

  .partners-overview-filter-wrapper {
    display: flex;
    gap: 40px;
    margin-bottom: 16px;

    .search-form {
      min-width: 423px;
    }
  }

  .pagination {
    margin-top: 16px;
    align-self: flex-end;
  }
}
