@import '../../../styles/colors';
@import '../../../styles/mixin';
@import '../../../styles/breakpoints';

.installation-offer-file-wrapper {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include tablet-breakpoint {
    display: block;
  }

  @include mobile-breakpoint {
    display: block;
  }

  .qr-code-wrapper {
    display: flex;
    flex-direction: column;

    @include lg-breakpoint {
      margin-top: 40px;
    }

    @include tablet-breakpoint {
      display: none;
    }

    @include mobile-breakpoint {
      display: none;
    }

    > h2 {
      margin-bottom: 10px;
    }

    .qr-code {
      margin-top: auto;
      padding: 12px;
      background-color: $white;
      align-self: flex-start;
      flex-grow: 0;

      svg {
        height: auto;
        width: 130px;
      }
    }
  }
}
