@import '../../../styles/colors';

.uploaded-file-details-wrapper {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 32px;

  .upload-file-description {
    display: flex;
    gap: 8px;
  }
}
