@import '../../../styles/colors';

.clients-wrapper {
  display: flex;
  flex-direction: column;

  .search-filter-wrapper {
    margin-bottom: 16px;
  }

  .pagination {
    align-self: flex-end;
    margin-top: 16px;
  }
}

.client-status-pending {
  color: map-get($status, info);

  &::before {
    background-color: map-get($status, info);
  }
}

.client-status-unsuccessful {
  color: map-get($status, error);

  &::before {
    background-color: map-get($status, error);
  }
}

.client-status-successful {
  color: map-get($status, success);

  &::before {
    background-color: map-get($status, success);
  }
}
