@import '../../styles/mixin';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';

.wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  padding: 40px;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: $radius-small;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 200;
  max-height: 95%;

  @include mobile-breakpoint {
    width: 100vw;
    padding: 24px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 10px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: map-get($beige, 1000);
    height: 300px;
  }

  h2 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  > p:first-of-type {
    margin-top: 32px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 100;
    width: 100%;
    white-space: pre-line;
    align-items: center;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 27px;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 200;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include backgroundOpacity($black, 0.68);
  backdrop-filter: blur(7.5px);
  z-index: 100;
}

.children-wrapper {
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  > *:not(div) {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
