@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/mixin';

.checkbox-wrapper {
  cursor: pointer;
  width: fit-content;

  & * {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  margin: 10px 8px;
  gap: 20px;
  position: relative;
}

.checkbox {
  display: inline-flex;
  position: relative;
  height: 24px;
  min-width: 24px;
  border: 2px solid map-get($brand, green);
  background-color: $white;

  &:focus {
    border-color: map-get($brand, orange);
    outline: 0;
  }

  &.squared {
    border-radius: 2px;
  }

  &.round {
    border-radius: 100px;
  }

  .icon {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-120%, -50%);
    width: 15px;
    height: 15px;
    z-index: 1;
    display: none;
  }
}

.checkbox.checked {
  border-color: map-get($orange, 200);
  background-color: map-get($orange, 200);
}

.checkbox.checked .icon {
  display: inline-block;
}

.checkbox-wrapper.disabled {
  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }

  .checkbox {
    border-color: map-get($beige, 750);

    .icon path {
      fill: map-get($beige, 750);
    }

    &.checked {
      background-color: map-get($beige, 750);

      > .icon path {
        fill: $white;
      }
    }
  }

  .sak-text {
    @include fontColorOpacity(map-get($brand, green), 0.5);
  }
}
