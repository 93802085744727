@import '../../../../../styles/breakpoints';

.admin-4eye-review-wrapper {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .admin-button-action-wrapper {
    display: flex;
    gap: 24px;
    margin-top: 48px;

    > button {
      width: 100%;
    }
  }
}
