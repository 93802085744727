@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.message-box-wrapper {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 1fr 10fr;
  gap: 16px;
  background-color: $white;
  cursor: pointer;

  .message-sender {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 1px;
    background-color: $light-orange;
    color: map-get($brand, green);
    font-size: 16px;
    line-height: 40px;
  }

  .message-box-content {
    h4 {
      width: 32ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include mobile-breakpoint {
        max-width: 24ch;
      }
    }

    p {
      max-width: 56ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: map-get($green, 1000);

      @include mobile-breakpoint {
        max-width: 30ch;
      }
    }
  }
}
