@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.project-item-wrapper {
  margin: 12px 0;
  padding: 24px;
  background-color: $white;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;

  @include lg-breakpoint {
    grid-template-columns: 1fr 1fr;
  }

  @include tablet-breakpoint {
    grid-template-columns: 1fr 1fr;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  @include xs-breakpoint {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .project-info-wrapper {
    margin-right: 12px;
    display: flex;
    gap: 24px;

    @include xs-breakpoint {
      border-bottom: 1px solid map-get($grey, semi);
    }

    @include tablet-breakpoint {
      gap: 18px;
    }

    @include mobile-breakpoint {
      gap: 10px;
    }

    @include xs-breakpoint {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .project-icon svg {
      width: 140px;
      height: 140px;

      @include tablet-breakpoint {
        width: 100px;
        height: 100px;
      }

      @include mobile-breakpoint {
        width: 70px;
        height: 70px;
      }
    }

    .project-info {
      margin-left: 12px;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      border-right: 1px solid map-get($grey, semi);

      @include xs-breakpoint {
        border-right: 0;
      }

      > span {
        display: flex;
        align-items: center;
        gap: 16px;

        @include xs-breakpoint {
          justify-content: center;
        }
      }
    }
  }

  .project-item-status {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: center;
    border-right: 1px solid map-get($grey, semi);
    gap: 16px;

    @include lg-breakpoint {
      border-right: 0;
    }

    @include tablet-breakpoint {
      border-right: 0;
    }

    @include tablet-breakpoint {
      border-right: 0;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
      text-align: center;
      gap: 0;
      border-right: 0;
    }
  }

  .project-item-buttons-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include tablet-breakpoint {
      flex-direction: row;
      grid-column: 1/-1;
    }

    @include mobile-breakpoint {
      flex-direction: row;
      grid-column: 1/-1;
      margin-top: 9px;
    }
  }

  > button {
    align-self: center;
    justify-self: center;
    width: fit-content;

    @include lg-breakpoint {
      grid-column: 1/-1;
      width: 100%;
      margin-top: 20px;
    }

    @include tablet-breakpoint {
      grid-column: 1/-1;
      width: 100%;
      margin-top: 20px;
    }

    @include mobile-breakpoint {
      grid-column: 1/-1;
      width: 100%;
      margin-top: 15px;
    }

    > span {
      width: fit-content;
    }
  }
}
