@import '../../../../../styles/colors';

.children-wrapper .compliance-check-wrapper {
  gap: 0;

  .table-name-heading {
    border-bottom: 1px solid map-get($brand, green);
    padding-bottom: 16px;
  }

  .table-wrapper:not(:last-child) {
    margin-bottom: 40px;
  }

  .button {
    align-self: flex-end;
  }
}

.wrapper:has(.compliance-check-wrapper) {
  width: 90%;
}
