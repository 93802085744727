@import '../../../styles/breakpoints';

.partner-edit-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding-top: 10px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
  }
}
