@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.date-input-wrapper {
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(201, 199, 195, 0.2);
  display: flex;
  input {
    padding: 0;
  }

  .calendar-icon {
    width: 24px;
    height: 24px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    fill: map-get($beige, 1000);
    z-index: 1;
  }

  .react-datepicker__close-icon {
    right: 12px;
    padding: 8px;
    &::after {
      content: url('../../assets/Icons/close.svg');
      background-color: transparent;
      height: 24px;
      width: 24px;
      font-size: 16px;
    }
  }
}

.calendar {
  font-family: 'Poppins-Regular';
  font-weight: 400;
  font-size: 15px;

  .react-datepicker__header {
    background-color: $white;
    border-bottom: none;
  }

  .calendar-header {
    padding: 0 16px;
    display: flex;
    column-gap: 8px;
    justify-content: center;

    @include xs-breakpoint {
      padding: 0 4px;
    }

    > button {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    select {
      padding: 12px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      outline: none;
      border: 1px solid map-get($beige, 500);
      border-radius: 4px;
      font-size: 15px;

      @include mobile-breakpoint {
        padding: 6px 10px;
      }
    }
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    margin-inline: auto;
    width: max-content;

    > div {
      width: 42px;
      text-align: center;
      align-self: center;

      @include xs-breakpoint {
        width: 32px;
      }
    }
  }

  .react-datepicker__month {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    margin: 10px auto;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: center;
    gap: 10px;

    > div {
      border-radius: 4px;
      width: 42px;
      height: 42px;
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;

      @include xs-breakpoint {
        width: 32px;
        height: 32px;
      }
    }

    .react-datepicker__day--keyboard-selected {
      background-color: transparent;
      border-color: #1f6655;
    }
  }

  .day-class {
    border: 1px solid map-get($beige, 500);
    transition: border-color 0.4s ease;

    &:hover {
      border-color: #1f6655;
      background-color: transparent;
    }
  }

  .selected-day {
    background-color: map-get($brand, orange);
    border-color: map-get($brand, orange);
    transition: all 0.4s ease;

    &:hover {
      background-color: map-get($orange, 600);
      border-color: map-get($orange, 600);
    }
  }
}

.error-text {
  color: map-get($status, error);
  margin-left: 20px;
}
