@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.file-selector-wrapper {
  padding: 12px 32px 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: $white;
  border: solid 2px map-get($orange, 200);

  @include tablet-breakpoint {
    height: 64px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }

  .icon {
    width: 34px;
    height: 34px;
    margin-left: 16px;
    cursor: pointer;
  }

  .file-icon {
    width: 24px;
    height: 24px;
  }

  .file-selector-label {
    padding-left: 8px;
    width: 100%;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;

    @include mobile-breakpoint {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;
    }
  }

  &.disabled {
    border-color: map-get($beige, 1000);
    background-color: map-get($brand, beige);

    & > .sak-text {
      color: map-get($green, 800);
    }

    .file-icon {
      path {
        fill: map-get($grey, base);
      }
    }
  }
}
