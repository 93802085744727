@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.checklist-item {
  cursor: pointer;
  height: 80px;
  display: flex;
  justify-content: space-between;

  background-color: $white;
  outline: solid 1px map-get($orange, 200);

  .labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;

    .checklist-price-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @include xl-breakpoint {
    padding: 16px;

    .labels {
      flex-direction: column;
      gap: 12px;
    }
  }

  @include tablet-breakpoint {
    height: 64px;
    padding: 16px 32px 16px 32px;
  }

  @include mobile-breakpoint {
    padding: 16px;

    .labels {
      flex-direction: column;

      .checklist-label {
        font-size: 15px;
        line-height: 1;
      }

      .checklist-value {
        font-size: 18px;
      }
    }
  }

  .checklist-label,
  .checklist-value {
    margin: auto 0;
    white-space: nowrap;
  }

  .checklist-label:not(.disabled),
  .checklist-value:not(.disabled) {
    color: map-get($green, 800);
  }

  .unit {
    margin-right: 1rem;
  }

  &.left {
    flex-direction: row-reverse;
  }

  &.checked {
    outline: solid 2px map-get($orange, 200);

    .checklist-label:not(.disabled),
    .checklist-value:not(.disabled) {
      color: map-get($green, 0);
    }
  }

  &:hover:not(.disabled) {
    outline: solid 2px map-get($green, 600);

    .checklist-label {
      color: map-get($green, 600);
    }

    .checklist-value {
      color: map-get($green, 0);
    }
  }

  &.large {
    padding: 24px 32px 24px 32px;
  }

  &.medium {
    height: 64px;
    padding: 16px 32px 16px 32px;
  }

  &.small {
    padding: 16px;

    .labels {
      flex-direction: column;

      .checklist-label {
        font-size: 15px;
      }

      .checklist-value {
        font-size: 18px;
      }
    }
  }

  &.disabled {
    cursor: default;
    background-color: map-get($brand, beige);
    outline: solid 2px map-get($beige, 1000);
  }
}
