@import '../../styles/colors';
@import '../../styles/breakpoints';

.status-field-wrapper {
  position: relative;
  background-color: $white;
  padding: 18px 32px;
  margin-bottom: 24px;

  @include tablet-breakpoint {
    text-align: center;
  }

  @include mobile-breakpoint {
    text-align: center;
  }

  .status {
    color: map-get($status, info);
    padding: 0 15px;

    &.accepted {
      color: map-get($status, success);
    }

    &.declined {
      color: map-get($status, error);
    }

    &.expired {
      color: map-get($status, error);
    }
  }

  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: map-get($status, info);
    border-radius: 50%;

    &.status-declined {
      background-color: map-get($status, error);
    }

    &.status-expired {
      background-color: map-get($status, error);
    }

    &.status-accepted {
      background-color: map-get($status, success);
    }

    @include mobile-breakpoint {
      display: none;
    }
  }
}

.circle.hide {
  display: none;
}
