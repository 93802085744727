.partner-commissions-wrapper {
  > div {
    display: flex;
    gap: 32px;

    .dropdown-list-wrapper {
      min-width: 400px;
      height: 80px;
    }
  }

  .button {
    margin-top: 32px;
  }
}
