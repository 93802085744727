@import '../../styles/colors';
@import '../../styles/breakpoints';

.sak-text {
  color: map-get($brand, green);

  &.italic {
    font-style: italic;
  }

  &.display {
    font-weight: 400;
    font-family: 'DM Serif';
    &.large {
      font-size: 100px;
      line-height: 100px;

      @include tablet-breakpoint {
        font-size: 58px;
        line-height: 60px;
      }

      @include mobile-breakpoint {
        font-size: 50px;
        line-height: 50px;
      }
    }
    &.medium {
      font-size: 70px;
      line-height: 76px;

      @include tablet-breakpoint {
        font-size: 42px;
        line-height: 44px;
      }

      @include mobile-breakpoint {
        font-size: 40px;
        line-height: 48px;
      }
    }
    &.small {
      font-size: 48px;
      line-height: 64px;

      @include tablet-breakpoint {
        font-size: 38px;
        line-height: 40px;
      }

      @include mobile-breakpoint {
        font-size: 38px;
        line-height: 40px;
      }
    }
  }

  &.headline {
    font-weight: 500;
    &.large {
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      font-family: 'Poppins-Bold';

      @include tablet-breakpoint {
        font-size: 24px;
        line-height: 32px;
      }

      @include mobile-breakpoint {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &.medium {
      font-size: 24px;
      line-height: 32px;
      font-family: 'Poppins-Bold';

      @include tablet-breakpoint {
        font-size: 20px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 18px;
      }
    }
    &.small {
      font-family: 'Poppins-Bold';
      &.v1 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
      }

      &.v2 {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  &.title {
    font-weight: 500;
    font-family: 'Poppins-Medium';

    &.large {
      font-size: 25px;
      line-height: 40px;

      @include tablet-breakpoint {
        font-size: 17px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 15px;
        line-height: 24px;
      }
    }
    &.medium {
      font-size: 20px;
      line-height: 24px;
    }
    &.small {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.body {
    font-weight: 400;

    &.large {
      font-size: 24px;
      line-height: 36px;
      font-family: 'Poppins-Regular';

      @include tablet-breakpoint {
        font-size: 17px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 16px;
      }
    }
    &.medium {
      font-size: 20px;
      line-height: 32px;
      font-family: 'Poppins-Regular';

      @include tablet-breakpoint {
        font-size: 16px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 15px;
      }
    }
    &.small {
      font-size: 12px;
      line-height: 16px;
      font-family: 'Poppins-Regular';
    }
  }

  &.label {
    font-weight: 400;

    &.large {
      font-family: 'Poppins-Medium';
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;

      &.v2 {
        font-family: 'Poppins-Regular';
      }

      @include tablet-breakpoint {
        font-size: 16px;
        line-height: 24px;
      }

      @include mobile-breakpoint {
        font-size: 15px;
      }
    }
    &.medium {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      font-family: 'Poppins-Regular';
    }
    &.small {
      font-size: 14px;
      line-height: 20px;
      font-family: 'Poppins-Medium';

      @include mobile-breakpoint {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &.disabled {
    color: map-get($grey, base);
  }
}
