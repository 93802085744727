@import '../../../styles/colors';

.partner-registration-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .partner-registration-form {
    width: 800px;
    display: flex;
    flex-direction: column;

    .email-link {
      transition: all 0.4s ease-out;
      color: map-get($brand, green);
      border-bottom: 1px solid transparent;

      &:hover {
        cursor: pointer;
        color: map-get($green, 600);

        path {
          fill: map-get($green, 600);
        }
      }
    }

    .company-data-form {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      gap: 24px;

      .street-house-wrapper {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 20px;
      }

      .company-address {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;

        > div:first-child {
          grid-column: 1 / 5;
        }

        > div:last-child {
          grid-column: 2 / 5;
        }
      }
    }
  }

  .installation-contract {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    > .checkbox-wrapper {
      margin-left: 0;
    }

    .download-partner-contract {
      padding: 12px 0;

      .sak-text.body {
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
        .sak-text.body {
          color: map-get($green, 600);
        }
        path {
          fill: map-get($green, 600);
        }
      }

      &:active {
        .sak-text.body {
          color: map-get($brand, orange);
        }
        path {
          fill: map-get($brand, orange);
        }
      }
    }
  }
}

.verification-modal {
  max-width: 600px;

  .helpdesk-text {
    color: map-get($green, 1000);

    .contact-us {
      border-bottom: 2px solid transparent;
      color: map-get($brand, green);
      cursor: pointer;
      transition: all 0.4s ease-out;

      &:hover {
        border-color: map-get($green, 600);
        color: map-get($green, 600);
      }
    }
  }
}
