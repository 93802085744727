@import '../../../../styles/colors';
@import '../../../../styles/variables';

.map-content-wrapper {
  display: flex;
  flex-direction: column;

  .partner-desc {
    max-width: 640px;
    margin-bottom: 24px;
  }

  .more-partners-btn {
    margin-top: 13px;
    align-self: flex-end;
  }

  .no-result-partners.sak-text {
    color: map-get($status, error);
  }

  .map-container {
    height: 254px;
    width: 100%;
    margin-bottom: 24px;
  }

  .checkbox-list-wrapper {
    margin-top: 24px;
    min-height: 240px;
    max-height: 300px;
    overflow-y: auto;

    .checkbox-wrapper {
      margin: 0;
      padding: 24px;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-bottom: 1px solid map-get($beige, 1000);
    }
  }
}
