@import '../../styles/breakpoints';

.questionnare-actions-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  @include mobile-breakpoint {
    gap: 20px;
    flex-direction: column-reverse;
    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 20px;
  
      @include mobile-breakpoint {
        flex-direction: column-reverse;
      }
    }
  }

  .next-step-action {
    margin-left: 40px;

    @include mobile-breakpoint {
      margin-left: 0px;
    }
  }
}
