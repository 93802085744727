.not-found-wrapper {
  display: flex;
  flex-direction: column;
  gap: 125px;
  justify-content: center;
  align-items: center;
  background: center no-repeat url('../../assets/Images/not-found.svg');
  padding-top: 180px;
  margin-bottom: 40px;
}
