@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.uploadOffer {
  padding-bottom: 280px;

  @include tablet-breakpoint {
    padding-bottom: 110px;
  }

  @include mobile-breakpoint {
    padding-bottom: 80px;
  }

  .uploadOfferDescription {
    margin-top: 40px;
    width: 50%;
    padding-right: 20px;

    @include lg-breakpoint {
      width: 100%;
    }

    @include tablet-breakpoint {
      width: 100%;
    }

    @include mobile-breakpoint {
      width: 100%;
    }
  }
}

.divided-section {
  @include backgroundOpacity($white, 0.6);
  margin-top: 16px;
}

.uploadFile-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  margin-top: 40px;

  @include tablet-breakpoint {
    grid-template-columns: 1fr;
  }

  @include mobile-breakpoint {
    grid-template-columns: 1fr;
  }

  @include lg-breakpoint {
    display: block;
  }

  .scanQRCode-section {
    align-self: flex-start;
    grid-column: 2/3;
    grid-row: 1;
    display: flex;
    gap: 40px;
    align-items: flex-start;

    @include lg-breakpoint {
      margin-top: 40px;
    }

    @include tablet-breakpoint {
      display: none;
    }

    @include mobile-breakpoint {
      display: none;
    }

    .qr-wrapper {
      padding: 24px;
      background-color: $white;

      svg {
        height: auto;
        width: 240px;
      }
    }

    .scanQRCode-heading {
      margin-bottom: 10px;
    }

    .uploadFile {
      grid-column: 1/2;
      grid-row: 1;
    }
  }
}
