@import '../../styles/breakpoints';

.home-main {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 35px;

  @include lg-breakpoint {
    padding-top: 0;
    flex-direction: column;
    gap: 70px;
  }

  @include tablet-breakpoint {
    padding-top: 0;
    flex-direction: column;
    gap: 70px;
  }

  @include mobile-breakpoint {
    padding-top: 0;
    flex-direction: column;
    gap: 40px;
  }
}
