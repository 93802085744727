.contracts-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .contract-actions {
    display: flex;
    column-gap: 20px;
  }
}
