@import '../../../styles/colors';

.credit-terms-wrapper {
  > h2 {
    margin-bottom: 10px;
  }

  .tabs {
    ul {
      margin-bottom: 16px;
    }
  }
}
