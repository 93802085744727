@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.profile-card-wrapper {
  width: 100%;
  max-width: 660px;
  padding: 40px;
  background-color: $white;
  display: grid;
  grid-template-areas:
    'icon title'
    'icon desc'
    'icon bttn';
  row-gap: 16px;
  column-gap: 20px;
  justify-content: start;
  align-items: center;

  @include lg-breakpoint {
    padding: 16px;
    row-gap: 0px;
    grid-template-areas:
      'icon title'
      'icon desc'
      'bttn bttn';
  }

  @include tablet-breakpoint {
    padding: 16px;
    row-gap: 0px;
    grid-template-areas:
      'icon title'
      'icon desc'
      'bttn bttn';
  }

  @include mobile-breakpoint {
    padding: 16px;
    row-gap: 0px;
    grid-template-areas:
      'icon title'
      'icon desc'
      'bttn bttn';
  }

  .profile-card-icon {
    width: 160px;
    height: 160px;
    grid-area: icon;

    @include lg-breakpoint {
      width: 88px;
      height: 88px;
    }

    @include tablet-breakpoint {
      width: 88px;
      height: 88px;
    }

    @include mobile-breakpoint {
      width: 80px;
      height: 80px;
    }
  }

  .profile-card-title {
    grid-area: title;
  }

  .profile-card-desc {
    grid-area: desc;
  }

  .profile-card-bttn {
    width: 240px;
    grid-area: bttn;

    @include lg-breakpoint {
      margin-top: 16px;
    }

    @include tablet-breakpoint {
      margin-top: 16px;
    }
    @include mobile-breakpoint {
      margin-top: 16px;
    }
  }
}
