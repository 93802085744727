@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.details {
  display: flex;
  flex-direction: column;

  & > .sak-text {
    margin-bottom: 32px;
  }

  .inputContainer {
    margin-top: 30px;
  }

  & > :nth-child(4) {
    margin-bottom: 24px;
  }

  & > :nth-child(6) {
    margin-bottom: 24px;
  }

  .offer-description {
    margin-top: 24px;
    padding: 18px 32px;
    background-color: $white;

    h4 {
      margin-bottom: 10px;
    }
  }

  .offer-contract {
    margin-top: 40px;

    .button {
      margin-top: 40px;

      path {
        fill: $white;
      }
    }
  }

  .installation-partner-heading {
    margin-bottom: 32px;
    margin-top: 40px;
  }
}
