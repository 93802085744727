@import '../../../styles/breakpoints';

.martial-status-checks {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;

  @include mobile-breakpoint {
    grid-template-columns: repeat(2, auto);
  }

  .marital-status-check {
    border: none;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 16px;

    @include mobile-breakpoint {
      padding: 10px 12px;
    }

    > .radio {
      width: 18px;
      height: 18px;
    }
  }
}
