@import '../../styles/colors';
@import '../../styles/breakpoints';

.filters-wrapper {
  position: relative;

  .button.filter-button {
    padding: 18px 36px;
    height: 100%;
    background-color: $white;
    border: 2px solid map-get($beige, 1000);
    border-bottom-color: map-get($beige, 1000);
    border-radius: 4px;

    &:hover {
      > span {
        color: map-get($brand, green);
      }

      path {
        fill: map-get($brand, green);
      }
    }

    &:focus {
      span {
        color: map-get($brand, green);
      }

      path {
        fill: map-get($brand, green);
      }
    }

    @include tablet-breakpoint {
      padding: 16px 20px;
    }

    @include mobile-breakpoint {
      padding: 14px 18px;
    }
  }

  .filter-dropdown {
    position: absolute;
    display: none;
    width: max-content;
    max-height: 500px;
    background-color: $white;
    padding: 8px 0;
    border-radius: 5px;
    left: 0;
    top: 103%;
    border: 1px solid map-get($beige, 500);
    box-shadow: 0px 2px 4px -1px rgba(20, 50, 80, 0.12), 0px 1px 10px 0px rgba(3, 12, 37, 0.1),
      0px 2px 5px 0px rgba(3, 12, 37, 0.12);
    z-index: 100;
    overflow-y: auto;
    max-height: 300px;

    &.left {
      right: 0;
      left: auto;
    }

    &:hover {
      cursor: pointer;
    }

    .filter-radio {
      .sak-text.body {
        color: map-get($brand, green);
      }

      .radio {
        width: 20px;
        height: 20px;
      }
    }

    .radio-wrapper {
      gap: 20px;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 10px 16px;
    }

    div:last-child {
      border: 0;
    }
  }
}

.filters-wrapper.show-dropdown {
  .button.filter-button {
    border-color: map-get($brand, green);
    background-color: transparent;
  }

  .filter-dropdown {
    display: block;
  }
}
