@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.inbox-wrapper {
  > h3 {
    margin-bottom: 40px;
  }

  .inbox-messages-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;

    @include tablet-breakpoint {
      grid-template-columns: 1fr;
    }

    @include mobile-breakpoint {
      grid-template-columns: 1fr;
    }
  }

  .inbox-message-list {
    .inbox-message-box-wrapper {
      min-height: 550px;
      max-height: 550px;
      background-color: $white;
      overflow-y: scroll;

      @include tablet-breakpoint {
        min-height: 340px;
        max-height: 340px;
      }

      @include mobile-breakpoint {
        min-height: 300px;
        max-height: 300px;
      }

      &::-webkit-scrollbar {
        width: 6px;
        max-height: 20px;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: map-get($brand, orange);
        outline: 1px solid map-get($brand, orange);
      }
    }

    .message-box-wrapper {
      border-bottom: 2px solid map-get($beige, 1000);

      &.selected {
        border-bottom: 2px solid map-get($brand, orange);
      }
    }
  }

  .selected-message {
    padding: 32px;
    background-color: $white;

    > h4 {
      margin-bottom: 32px;
    }

    > .button {
      margin-top: 32px;
    }

    .message-content {
      white-space: pre-line;
    }
  }

  .inbox-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }
}
