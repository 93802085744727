@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.search-filter-wrapper {
  position: relative;
  width: 50%;
  display: flex;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 128px;

  @include tablet-breakpoint {
    width: 100%;
  }

  @include mobile-breakpoint {
    width: 100%;
  }

  & .search-form {
    border-right: 0;
  }

  .search-filter-dropdown-wrapper {
    position: relative;

    .filter-button {
      padding: 18px 36px;
      height: 100%;
      background-color: $white;
      border: 2px solid map-get($beige, 1000);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      @include tablet-breakpoint {
        padding: 16px 20px;
      }

      @include mobile-breakpoint {
        padding: 14px 18px;
      }
    }
  }

  .search-form {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;

    @include tablet-breakpoint {
      width: 100%;
    }

    @include mobile-breakpoint {
      width: 100%;
    }
  }

  .filter-dropdown {
    position: absolute;
    display: none;
    width: max-content;
    max-height: 500px;
    background-color: $white;
    padding: 8px 0;
    border-radius: 5px;
    left: 0;
    top: 103%;
    border: 1px solid map-get($beige, 500);
    box-shadow: 0px 2px 4px -1px rgba(20, 50, 80, 0.12), 0px 1px 10px 0px rgba(3, 12, 37, 0.1),
      0px 2px 5px 0px rgba(3, 12, 37, 0.12);
    z-index: 100;
    overflow: scroll;

    &:hover {
      cursor: pointer;
    }

    .filter-radio {
      .sak-text.body {
        color: map-get($brand, green);
      }

      .radio {
        width: 20px;
        height: 20px;
      }
    }

    .radio-wrapper {
      gap: 20px;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    div:last-child {
      border: 0;
    }
  }

  .open-filter-dropdown {
    display: block;
  }
}
