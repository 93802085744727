@import '../../styles/colors';
@import '../../styles/breakpoints';

.children-wrapper .leave-modal-buttons {
	flex-direction: row;
	margin-top: 24px;
	justify-content: space-between;
	gap: 24px;

	>* {
		flex: 1;
	}

	@include tablet-breakpoint {
		width: 100%;
	}

	@include mobile-breakpoint {
		width: 100%;
	}

	@include xs-breakpoint {
		flex-direction: column;
		flex-flow: column-reverse;
	}
}