@import '../../styles/colors';
@import '../../styles/breakpoints';

.pagination {
  display: flex;
  gap: 8px;

  .pagination-btn {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 2px solid map-get($beige, 500);
    border-radius: 4px;
    cursor: pointer;

    @include mobile-breakpoint {
      width: 35px;
      height: 35px;
    }

    @include xs-breakpoint {
      width: 25px;
      height: 25px;
    }

    &:hover {
      color: map-get($green, 600);
      border-color: map-get($green, 600);
    }
  }

  .active {
    color: map-get($brand, orange);
    border: 2px solid map-get($brand, orange);
  }

  .disabled {
    pointer-events: none;
    color: map-get($grey, base);
  }
}
