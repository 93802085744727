.partner-project-info {
  > h3 {
    margin-bottom: 32px;
  }

  .partner-project-space {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 24px;
  }

  .partner-project-action-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
}
