@import '../../styles/colors';
@import '../../styles/breakpoints';

.kyc-result-wrapper {
  width: 52%;

  @include tablet-breakpoint {
    width: 100%;
  }

  @include mobile-breakpoint {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  svg {
    width: 188px;
    height: 188px;
    margin-bottom: 40px;
    align-self: center;
  }

  > div {
    h2 {
      margin-bottom: 40px;
    }
  }

  ul li::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: map-get($brand, green);
    margin-right: 10px;
    vertical-align: middle;
  }

  ul {
    margin-bottom: 24px;
  }

  .kyc-action-wrapper {
    margin-top: 24px;
    display: flex;
    gap: 20px;

    @include tablet-breakpoint {
      flex-direction: column;
    }

    @include mobile-breakpoint {
      flex-direction: column;
    }
  }
}

.kyc-success-wrapper {
  .kyc-success-notification {
    text-align: center;
    margin-bottom: 56px;
  }

  h4 {
    margin-bottom: 24px;
  }

  .button {
    align-self: flex-start;
  }
}

.kyc-error-wrapper {
  .kyc-error-indentification {
    text-align: center;
    margin-bottom: 24px;
  }

  h4 {
    margin-bottom: 24px;
  }
}
