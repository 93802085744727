@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/mixin';
@import '../../styles/breakpoints';

.tooltip-wrapper {
  position: relative;
  cursor: pointer;
  width: 26px;
  height: 26px;

  h3 {
    margin-bottom: 10px;
  }

  @include mobile-breakpoint {
    width: 24px;
    height: 24px;
  }
}

.tooltip-text-wrapper {
  position: absolute;
  padding: 16px;
  border-radius: $radius-medium;
  background-color: map-get($brand, green);
  right: -20px;
  top: 100%;
  margin-top: 10px;
  width: fit-content;
  min-width: 380px;
  display: none;
  z-index: 100;

  @include tablet-breakpoint {
    min-width: 300px;
  }

  @include mobile-breakpoint {
    min-width: 250px;
  }

  .sak-text {
    color: $white;
  }

  @include mobile-breakpoint {
    width: fit-content;
  }

  @include tablet-breakpoint {
    width: fit-content;
  }
}

.info-icon {
  width: 100%;
  height: 100%;

  path {
    fill: map-get($beige, 1000);
  }

  &:hover {
    path {
      fill: map-get($brand, green);
    }

    & + .tooltip-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &::before {
        @include arrow-top;
        position: absolute;
        right: 20px;
        top: 0;
        transform: translateY(-50%);
      }
    }
  }
}
