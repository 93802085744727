@import '../../../styles/mixin';
@import '../../../styles/breakpoints';

.offer-calculator {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;

  @include tablet-breakpoint {
    padding-left: 0;
  }

  @include mobile-breakpoint {
    padding-left: 0;
  }

  > h3 {
    margin-bottom: 25px;
  }

  > p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  > button {
    margin-top: 55px;
    align-self: center;
  }
}
