@import '../../styles/breakpoints';

.address-data-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 24px;
  }

  > div:not(:nth-child(2)) {
    display: grid;
    grid-template-columns: 5fr 1fr;
    gap: 24px;
  }

  > div:nth-child(3) {
    grid-template-columns: 2fr 1fr;
  }

  @include mobile-breakpoint {
    > div:nth-child(2) {
      grid-template-columns: 2fr 4fr;
      gap: 12px;
    }

    > div:not(:nth-child(2)) {
      grid-template-columns: 4fr 2fr;
      gap: 12px;
    }
  }

  @include tablet-breakpoint {
    > div:nth-child(2) {
      grid-template-columns: 2fr 4fr;
    }

    > div:not(:nth-child(2)) {
      grid-template-columns: 4fr 2fr;
    }
  }
}
