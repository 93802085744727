@import '../../../styles/breakpoints';

.user-project-details-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  column-gap: 40px;

  > div:nth-child(2) {
    isolation: isolate;
  }

  @include xl-breakpoint {
    grid-template-columns: 5fr 4fr;
  }

  @include lg-breakpoint {
    display: flex;
    flex-direction: column;
  }

  @include tablet-breakpoint {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  .user-upload-action-wrapper {
    > h3 {
      margin-top: 40px;
      margin-bottom: 32px;
    }

    > .button {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

.heading {
  margin-bottom: 40px;
}
