@import '../../styles/colors';

.email-link {
  transition: all 0.4s ease-out;
  color: map-get($brand, green);
  border-bottom: 1px solid transparent;

  &:hover {
    cursor: pointer;
    color: map-get($green, 600);

    path {
      fill: map-get($green, 600);
    }
  }
}
