@import '../../styles/breakpoints';

.customer-number {
  margin-top: 20px;
}

.profile-cards {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, max-content));
  gap: 40px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
  }
}
