@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/breakpoints';

.projects-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .search-form {
    margin-top: 128px;
    width: 50%;

    @include tablet-breakpoint {
      width: 100%;
      margin-top: 54px;
    }

    @include mobile-breakpoint {
      width: 100%;
      margin-top: 28px;
    }
  }

  .projects-list {
    min-height: 810px;

    @include tablet-breakpoint {
      min-height: 700px;
    }

    @include tablet-breakpoint {
      min-height: 550px;
    }
  }

  > div:last-child {
    align-self: flex-end;
    margin-top: auto;
  }
}
